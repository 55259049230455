import Box from '@material-ui/core/Box';
import React, { useState, useEffect } from 'react';
import { DoorDocument } from '../../../pages/DoorAdd';
import AddedFile from '../../AddedFile';
import DropZone from '../../DropZone';
import { useDocumentTypesQuery, DocumentType, Document } from '../../../queries';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useFormContext } from 'react-hook-form';

interface DoorDocumentsUploadProps {
  documents: DoorDocument[];
  modelDocuments?: Partial<Document>[] | null;
  setDocuments: React.Dispatch<React.SetStateAction<DoorDocument[]>>;
  documentTypeCodes?: string[];
}

export default function DoorDocumentsUpload({
  documents,
  modelDocuments,
  setDocuments,
  documentTypeCodes,
}: DoorDocumentsUploadProps) {
  const [documentTypes, setDocumentTypes] = useState<Partial<DocumentType>[]>([]);

  const { loading: loadingDocTypes, data } = useDocumentTypesQuery();
  useEffect(() => {
    if (data) {
      let validDocumentTypes = data.documentTypes ?? [];
      if (documentTypeCodes && documentTypeCodes.length > 0) {
        validDocumentTypes = validDocumentTypes.filter((vd) => documentTypeCodes.includes(vd.code));
      }

      setDocumentTypes(validDocumentTypes);
    }
  }, [data, documentTypeCodes]);

  const defaultValues: any = {};
  documents.forEach((d, i) => {
    defaultValues[`documentTypesAttached[${i}].id`] = d.documentTypeCode;
  });

  const { register, reset } = useFormContext();

  // having to use useEffect and reset to initialize the documet types. for some obscure reason defaultValues on useform does not work
  useEffect(() => {
    if (documents) {
      reset(defaultValues, {
        isValid: true, // This ensures the validity of the form is reevaluated
      });
    }
    // eslint-disable-next-line
  }, [documents, reset]); // do not add defaultValues as a dependency to avoid re rendering loop

  const onFilesAdded = (selectedFiles: File[]) => {
    const newDocuments: DoorDocument[] = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      // have to provide a default description otherwise React complains about controlled/uncontrolled components in console
      newDocuments.push({ file, documentTypeCode: '' });
    }
    setDocuments((prev) => [...prev, ...newDocuments]);
  };

  const onFileRemoved = (fileIndex: number) => {
    const filesCopy = Array.from(documents);
    filesCopy.splice(fileIndex, 1);
    setDocuments(filesCopy);
  };

  const onDocumentTypeChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    fileIndex: number
  ) => {
    const documentTypeCode = String(e.target.value);
    setDocuments((prev) => prev.map((x, i) => (i === fileIndex ? { ...x, documentTypeCode } : x)));
  };

  // handling error manually here
  const hasError = (doc: DoorDocument): boolean => {
    return doc.documentTypeCode === undefined || doc.documentTypeCode.length === 0;
  };

  return (
    <Box>
      <DropZone
        onFilesAdded={onFilesAdded}
        title="Upload documentation"
        accept=".pdf"
        acceptHelperText="Accepted format: PDF"
        multiple
        maxSizeMb={50}
      />
      <Box mt={3} mb={3}>
        <Typography>Required documentation: Installation guide, Fire certificate, Warranty.</Typography>
      </Box>

      {documents
        .filter((d) => (documentTypeCodes ?? []).includes(d.documentTypeCode) || !d.documentTypeCode) // Might be false if it is being edited
        .map((document, i) => (
          <AddedFile
            key={i}
            documentType={document.documentTypeCode}
            fileName={document.file.name}
            onRemove={() => onFileRemoved(i)}
          >
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor={`documentTypesAttached[${i}].id`} error={hasError(document)}>
                Type
              </InputLabel>

              <Select
                label="Type"
                native
                inputProps={{
                  name: `documentTypesAttached[${i}].id`,
                  id: `documentType[${i}]IdInput`,
                }}
                inputRef={register({ required: 'Type required' })}
                onChange={(e) => onDocumentTypeChange(e, i)}
                error={hasError(document)}
              >
                <option aria-label="None" value="" />

                {!loadingDocTypes &&
                  documentTypes &&
                  documentTypes.map((documentType, i) => (
                    <option value={documentType.code} key={documentType.code}>
                      {documentType.name}
                    </option>
                  ))}
              </Select>
              <FormHelperText error={hasError(document)}>
                {hasError(document) ? 'Type is required' : undefined}
              </FormHelperText>
            </FormControl>
          </AddedFile>
        ))}
      {modelDocuments &&
        modelDocuments
          .filter((d) => (documentTypeCodes ?? []).includes(d.documentType!.code!))
          .map((document, i) => (
            <AddedFile key={i} documentType={document.documentType?.code} fileName={document.originalFilename}>
              <Typography>{document.documentType?.name}</Typography>
            </AddedFile>
          ))}
    </Box>
  );
}
