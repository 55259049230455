import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcceptInviteInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type AccontInspectionsOverview = {
  sites: Array<SiteInspection>;
};

export type AssignDoorToSiteInput = {
  doorId: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
};

export type BillingEvent = {
  billingEventType: BillingEventType;
  company?: Maybe<Company>;
  door?: Maybe<Door>;
  eventDateAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type BillingEventType = {
  code: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type BillingEventsSummary = {
  billingEmail?: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
  companyName: Scalars['String'];
  doorCount: Scalars['Int'];
  inspections: Scalars['Int'];
  installations: Scalars['Int'];
  installationsReported: Scalars['Int'];
};

export type ChecklistAnswer = {
  answer: Scalars['String'];
  checklistQuestion: ChecklistQuestion;
  checklistQuestionId: Scalars['Float'];
  documentId?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  inspectionId?: Maybe<Scalars['Float']>;
  installationId?: Maybe<Scalars['Float']>;
  photoKey?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Document>>;
  reason?: Maybe<Scalars['String']>;
};

export type ChecklistAnswerDocument = {
  photoKey: Scalars['String'];
};

export type ChecklistAnswerInput = {
  answer: Scalars['String'];
  photos?: Maybe<Array<ChecklistAnswerPhotoInput>>;
  questionId: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
};

export type ChecklistAnswerPhotoInput = {
  file?: Maybe<Scalars['Upload']>;
  photoKey?: Maybe<Scalars['String']>;
  photoUUID?: Maybe<Scalars['String']>;
};

export type ChecklistAnswerWithDocument = {
  answer: Scalars['String'];
  checklistQuestion: ChecklistQuestion;
  documents?: Maybe<Array<DocumentWithPhotoKey>>;
  inspectionId: Scalars['Float'];
  installationId: Scalars['Float'];
  reason?: Maybe<Scalars['String']>;
};

export type ChecklistPhotoInput = {
  file: Scalars['Upload'];
  photoUUID: Scalars['String'];
};

export type ChecklistQuestion = {
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Company = {
  admin?: Maybe<User>;
  apiKey?: Maybe<Scalars['String']>;
  billingAddressOne?: Maybe<Scalars['String']>;
  billingAddressTwo?: Maybe<Scalars['String']>;
  billingCity?: Maybe<Scalars['String']>;
  billingEmail?: Maybe<Scalars['String']>;
  billingPostcode?: Maybe<Scalars['String']>;
  canReportInstallations: Scalars['Boolean'];
  contactPhone?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Document>>;
  id: Scalars['ID'];
  inspectionCertificateAdditionalText?: Maybe<Scalars['String']>;
  installationCertificateAdditionalText?: Maybe<Scalars['String']>;
  isT1Contractor?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  passedReportsForCurrentMonth: Scalars['Int'];
  sites: Array<Site>;
  status: Status;
  types: Array<CompanyType>;
};

export type CompanyHeader = {
  billingEmail?: Maybe<Scalars['String']>;
  companyTypes: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  passedReports: Scalars['Int'];
  status: Scalars['String'];
};

export type CompanySite = {
  company: Company;
  site: Site;
};

export type CompanyType = {
  code: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
};

export enum CompanyTypes {
  FacilityManagement = 'FacilityManagement',
  Inspection = 'Inspection',
  Installer = 'Installer',
  Manufacturer = 'Manufacturer',
  Service = 'Service'
}

export type DashboardResponse = {
  accontInspectionsOverview?: Maybe<AccontInspectionsOverview>;
  accountInspectionsOverview: AccontInspectionsOverview;
  avgRepairMinutesLast30Days?: Maybe<Scalars['Int']>;
  avgRepairMinutesPrevious30Days?: Maybe<Scalars['Int']>;
  nbDoors: Scalars['Int'];
  nbDoorsAddedThisMonth: Scalars['Int'];
  nbInspectionsDueByMonth?: Maybe<NbInspectionsDueByMonth>;
  nbInspectionsDueThisMonth: Scalars['Int'];
  nbInspectionsFailedLast30Days: Scalars['Int'];
  nbInspectionsFailedPrevious30Days: Scalars['Int'];
  siteInspectionsOverview?: Maybe<SiteInspectionsOverview>;
};


export type DescriptedFileInput = {
  description?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  id?: Maybe<Scalars['ID']>;
};

export type Document = {
  description?: Maybe<Scalars['String']>;
  documentType: DocumentType;
  id: Scalars['ID'];
  originalFilename: Scalars['String'];
  uuid: Scalars['String'];
};

export type DocumentInput = {
  documentTypeCode: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
  id?: Maybe<Scalars['ID']>;
};

export type DocumentType = {
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DocumentWithPhotoKey = {
  documentId: Scalars['Float'];
  photoKey?: Maybe<Scalars['String']>;
};

export type Door = {
  activeDoorAssignments?: Maybe<Array<DoorAssignment>>;
  addedBy?: Maybe<User>;
  barcode?: Maybe<Scalars['String']>;
  /** @deprecated Use the activeDoorAssignments field instead */
  contractorAssignment?: Maybe<DoorAssignment>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  documents?: Maybe<Array<Document>>;
  doorParts?: Maybe<DoorParts>;
  doorReference?: Maybe<Scalars['String']>;
  fdRating?: Maybe<FdRating>;
  fireCertificateComplete: Scalars['Boolean'];
  history?: Maybe<Array<DoorHistory>>;
  id: Scalars['ID'];
  inspectionFrequency?: Maybe<Scalars['Int']>;
  inspectionStartedBy?: Maybe<User>;
  installationGuideComplete: Scalars['Boolean'];
  installationStartedBy?: Maybe<User>;
  installer?: Maybe<Company>;
  installerId?: Maybe<Scalars['ID']>;
  lastInspection?: Maybe<Inspection>;
  lastestInstallationInstalledBy?: Maybe<Scalars['String']>;
  latestInstallationDate?: Maybe<Scalars['DateTime']>;
  leaf1AccousticRating?: Maybe<Scalars['String']>;
  leaf1CoreSupplier?: Maybe<Scalars['String']>;
  leaf1Height?: Maybe<Scalars['String']>;
  leaf1Width?: Maybe<Scalars['String']>;
  leaf2AccousticRating?: Maybe<Scalars['String']>;
  leaf2CoreSupplier?: Maybe<Scalars['String']>;
  leaf2Height?: Maybe<Scalars['String']>;
  leaf2Width?: Maybe<Scalars['String']>;
  lineBarcode?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  locationOrDoorRef?: Maybe<Scalars['String']>;
  locationType?: Maybe<LocationType>;
  manufacturedBy?: Maybe<Company>;
  model?: Maybe<DoorModel>;
  nbLeafs?: Maybe<Scalars['Float']>;
  nextInspectionDate?: Maybe<Scalars['DateTime']>;
  partsListComplete: Scalars['Boolean'];
  projectReference?: Maybe<Scalars['String']>;
  readOnly: Scalars['Boolean'];
  reportedInstallationDate?: Maybe<Scalars['DateTime']>;
  site?: Maybe<Site>;
  status: Status;
  /** @deprecated Use the activeDoorAssignments field instead */
  subContractorAssignment?: Maybe<DoorAssignment>;
  tagId?: Maybe<Scalars['String']>;
  transferredToFacilityManagementAt?: Maybe<Scalars['DateTime']>;
  transferredToInstallerAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  uuid?: Maybe<Scalars['String']>;
  warrantyComplete: Scalars['Boolean'];
};

export type DoorAssignment = {
  assignedBy?: Maybe<User>;
  contractorCompany: Company;
  createdAt: Scalars['DateTime'];
  doorAssignmentType: DoorAssignmentType;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  workType: WorkType;
};

export type DoorAssignmentType = {
  code: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
};

export enum DoorFilterStages {
  All = 'All',
  Failed = 'Failed',
  Incomplete = 'Incomplete',
  Ready = 'Ready',
  Transferred = 'Transferred'
}

export type DoorHistory = {
  addedBy: User;
  addedByCompanyName: Scalars['String'];
  addedByName: Scalars['String'];
  additionalInfoJson?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  historyEventType: HistoryEventType;
  id: Scalars['ID'];
};

export type DoorIncompleteInput = {
  inspectionFrequency?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  locationTypeId?: Maybe<Scalars['ID']>;
  nbLeafs?: Maybe<Scalars['Float']>;
  siteId: Scalars['ID'];
  tagId: Scalars['String'];
  uuid?: Maybe<Scalars['String']>;
};

export type DoorInput = {
  additionalComments?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  chain?: Maybe<Scalars['String']>;
  closer?: Maybe<Scalars['String']>;
  cylinder?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentInput>>;
  doorLeafs?: Maybe<Array<DoorLeafInput>>;
  doorModelId?: Maybe<Scalars['ID']>;
  doorReference?: Maybe<Scalars['String']>;
  dropSeal?: Maybe<Scalars['String']>;
  fdRatingId?: Maybe<Scalars['Float']>;
  handles?: Maybe<Scalars['String']>;
  hinges?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  inspectionFrequency?: Maybe<Scalars['Int']>;
  intumescentStrip?: Maybe<Scalars['String']>;
  keeps?: Maybe<Scalars['String']>;
  letterbox?: Maybe<Scalars['String']>;
  lineBarcode?: Maybe<Scalars['String']>;
  lockLatch?: Maybe<Scalars['String']>;
  numerals?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<DescriptedFileInput>>;
  projectReference?: Maybe<Scalars['String']>;
  smokeSeals?: Maybe<Scalars['String']>;
  spyhole?: Maybe<Scalars['String']>;
  tagId?: Maybe<Scalars['String']>;
  thresholdStrip?: Maybe<Scalars['String']>;
  weatherSeals?: Maybe<Scalars['String']>;
};

export type DoorInspectionOverview = {
  doorId: Scalars['ID'];
  doorStatusCode: Scalars['String'];
  inspectionOverviewStatus: Scalars['String'];
  locationOrDoorRef: Scalars['String'];
};

export type DoorLeafInput = {
  acousticRating?: Maybe<Scalars['String']>;
  coreSupplier?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type DoorModel = {
  addedBy?: Maybe<User>;
  addedByCompany: Company;
  coreSupplier?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  documents?: Maybe<Array<Document>>;
  doorModelParts?: Maybe<DoorModelParts>;
  fdRating?: Maybe<FdRating>;
  fireCertificateComplete: Scalars['Boolean'];
  id: Scalars['ID'];
  installationGuideComplete: Scalars['Boolean'];
  name: Scalars['String'];
  partsListComplete: Scalars['Boolean'];
  warrantyComplete: Scalars['Boolean'];
};

export type DoorModelDocumentsInput = {
  documents?: Maybe<Array<DocumentInput>>;
  doorModelId?: Maybe<Scalars['ID']>;
};

export type DoorModelInput = {
  additionalComments?: Maybe<Scalars['String']>;
  closer?: Maybe<Scalars['String']>;
  coreSupplier?: Maybe<Scalars['String']>;
  cylinder?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentInput>>;
  dropSeal?: Maybe<Scalars['String']>;
  fdRatingId?: Maybe<Scalars['Float']>;
  handles?: Maybe<Scalars['String']>;
  hinges?: Maybe<Scalars['String']>;
  intumescentStrip?: Maybe<Scalars['String']>;
  letterbox?: Maybe<Scalars['String']>;
  lockLatch?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  smokeSeals?: Maybe<Scalars['String']>;
  spyhole?: Maybe<Scalars['String']>;
  thresholdStrip?: Maybe<Scalars['String']>;
};

export type DoorModelParts = {
  additionalComments?: Maybe<Scalars['String']>;
  closer?: Maybe<Scalars['String']>;
  cylinder?: Maybe<Scalars['String']>;
  doorModel: DoorModel;
  dropSeal?: Maybe<Scalars['String']>;
  handles?: Maybe<Scalars['String']>;
  hinges?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intumescentStrip?: Maybe<Scalars['String']>;
  letterbox?: Maybe<Scalars['String']>;
  lockLatch?: Maybe<Scalars['String']>;
  smokeSeals?: Maybe<Scalars['String']>;
  spyhole?: Maybe<Scalars['String']>;
  thresholdStrip?: Maybe<Scalars['String']>;
};

export type DoorParts = {
  additionalComments?: Maybe<Scalars['String']>;
  chain?: Maybe<Scalars['String']>;
  closer?: Maybe<Scalars['String']>;
  cylinder?: Maybe<Scalars['String']>;
  door: Door;
  dropSeal?: Maybe<Scalars['String']>;
  handles?: Maybe<Scalars['String']>;
  hinges?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intumescentStrip?: Maybe<Scalars['String']>;
  keeps?: Maybe<Scalars['String']>;
  letterbox?: Maybe<Scalars['String']>;
  lockLatch?: Maybe<Scalars['String']>;
  numerals?: Maybe<Scalars['String']>;
  smokeSeals?: Maybe<Scalars['String']>;
  spyhole?: Maybe<Scalars['String']>;
  thresholdStrip?: Maybe<Scalars['String']>;
  weatherSeals?: Maybe<Scalars['String']>;
};

export type DoorRepairing = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  reason: Scalars['String'];
};

export type DoorRetirement = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  reason: Scalars['String'];
};

export type DoorUpdateInput = {
  additionalComments?: Maybe<Scalars['String']>;
  chain?: Maybe<Scalars['String']>;
  closer?: Maybe<Scalars['String']>;
  cylinder?: Maybe<Scalars['String']>;
  dropSeal?: Maybe<Scalars['String']>;
  fdRatingId?: Maybe<Scalars['ID']>;
  handles?: Maybe<Scalars['String']>;
  hinges?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intumescentStrip?: Maybe<Scalars['String']>;
  keeps?: Maybe<Scalars['String']>;
  leaf1AcousticRating?: Maybe<Scalars['String']>;
  leaf1CoreSupplier?: Maybe<Scalars['String']>;
  leaf1Height?: Maybe<Scalars['Float']>;
  leaf1Width?: Maybe<Scalars['Float']>;
  leaf2AcousticRating?: Maybe<Scalars['String']>;
  leaf2CoreSupplier?: Maybe<Scalars['String']>;
  leaf2Height?: Maybe<Scalars['Float']>;
  leaf2Width?: Maybe<Scalars['Float']>;
  letterbox?: Maybe<Scalars['String']>;
  lockLatch?: Maybe<Scalars['String']>;
  numerals?: Maybe<Scalars['String']>;
  smokeSeals?: Maybe<Scalars['String']>;
  spyhole?: Maybe<Scalars['String']>;
  thresholdStrip?: Maybe<Scalars['String']>;
  weatherSeals?: Maybe<Scalars['String']>;
};

export type DoorsInput = {
  doorId: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
};

export type EditUserInput = {
  roleIds: Array<Scalars['Float']>;
  userId: Scalars['Float'];
};

export type FdRating = {
  id: Scalars['Float'];
  value: Scalars['String'];
};

export type FailureRisk = {
  code: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type FileDownloadResponse = {
  path: Scalars['String'];
};

export type FireStoppingType = {
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type HistoryEventType = {
  code: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type IdListInput = {
  ids: Array<Scalars['Float']>;
};

export type InspectDoorInput = {
  answers: Array<ChecklistAnswerInput>;
  doorId: Scalars['ID'];
};

export type Inspection = {
  completedDate?: Maybe<Scalars['DateTime']>;
  completedMessage?: Maybe<Scalars['String']>;
  failureRisk?: Maybe<FailureRisk>;
  id: Scalars['ID'];
  isSignedOff?: Maybe<Scalars['Boolean']>;
  report?: Maybe<Document>;
};

export type InstallDoorInput = {
  answers: Array<ChecklistAnswerInput>;
  doorId: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  locationTypeId?: Maybe<Scalars['ID']>;
  siteId: Scalars['ID'];
  tcAgreed?: Maybe<Scalars['Boolean']>;
};

export type Installation = {
  completedDate: Scalars['DateTime'];
  id: Scalars['ID'];
  isSignedOff?: Maybe<Scalars['Boolean']>;
  tcAgreed?: Maybe<Scalars['Boolean']>;
};

export type LocationType = {
  code: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type MarkDoorForRepairInput = {
  doorId: Scalars['ID'];
  message: Scalars['String'];
};

export type Mutation = {
  acceptInvite: Array<Role>;
  addDoor: Door;
  addIncompleteDoor: Door;
  addSite: Scalars['Boolean'];
  addToMySites: Scalars['Boolean'];
  addUser: User;
  assignBarcodeTag: Door;
  assignDoorTag: Door;
  assignDoorsToContractor: Scalars['Boolean'];
  assignDoorsToSite: Scalars['Boolean'];
  cancelInspections: Scalars['Boolean'];
  cancelInstallations: Scalars['Boolean'];
  deleteDoorModels: Scalars['Boolean'];
  disableDoors: Scalars['Boolean'];
  disableUsers: Scalars['Boolean'];
  editCompany: Company;
  editSite: Site;
  editUser: Scalars['Boolean'];
  enableUsers: Scalars['Boolean'];
  generateApiKey: Scalars['String'];
  inspectDoor: Scalars['Boolean'];
  installDoor: Scalars['Boolean'];
  inviteCompany: Company;
  markDoorForRepair: Scalars['Boolean'];
  passwordResetRequest: Scalars['Boolean'];
  recordDoorNotAccessible: Scalars['Boolean'];
  removeContractorFromDoors: Scalars['Boolean'];
  removeFromMySites: Scalars['Boolean'];
  repairDoor: Scalars['Boolean'];
  reportInstallation: Scalars['Boolean'];
  requestAccount: Scalars['Boolean'];
  resetPassword: Array<Role>;
  retireDoors: Scalars['Boolean'];
  saveDoorModel: Scalars['Boolean'];
  saveDoorModelDocuments: DoorModel;
  signOffInspection: Scalars['Boolean'];
  signOffInstallation: Scalars['Boolean'];
  startInspection: Scalars['Boolean'];
  startInstallation: Scalars['Boolean'];
  transferDoors: Scalars['Boolean'];
  updateDoor: Door;
  uploadChecklistPhoto: Scalars['Float'];
  uploadDoorsFile: Scalars['Boolean'];
};


export type MutationAcceptInviteArgs = {
  acceptInviteInput: AcceptInviteInput;
};


export type MutationAddDoorArgs = {
  door: DoorInput;
};


export type MutationAddIncompleteDoorArgs = {
  door: DoorIncompleteInput;
};


export type MutationAddSiteArgs = {
  siteInput: SiteInput;
};


export type MutationAddToMySitesArgs = {
  idListInput: IdListInput;
};


export type MutationAddUserArgs = {
  newUserInput: NewUserInput;
};


export type MutationAssignBarcodeTagArgs = {
  barcode: Scalars['String'];
  tag: Scalars['String'];
};


export type MutationAssignDoorTagArgs = {
  id: Scalars['ID'];
  tag: Scalars['String'];
};


export type MutationAssignDoorsToContractorArgs = {
  destinationCompanyId: Scalars['ID'];
  destinationUserEmail: Scalars['String'];
  ids: Array<Scalars['Float']>;
  workTypeCode: Scalars['String'];
};


export type MutationAssignDoorsToSiteArgs = {
  doorsInput: Array<AssignDoorToSiteInput>;
  siteId: Scalars['ID'];
};


export type MutationCancelInspectionsArgs = {
  idListInput: IdListInput;
};


export type MutationCancelInstallationsArgs = {
  idListInput: IdListInput;
};


export type MutationDeleteDoorModelsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationDisableDoorsArgs = {
  idListInput: IdListInput;
};


export type MutationDisableUsersArgs = {
  idListInput: IdListInput;
};


export type MutationEditCompanyArgs = {
  companyInput: NewCompanyInput;
};


export type MutationEditSiteArgs = {
  siteInput: SiteInput;
};


export type MutationEditUserArgs = {
  editUserInput: EditUserInput;
};


export type MutationEnableUsersArgs = {
  idListInput: IdListInput;
};


export type MutationGenerateApiKeyArgs = {
  companyId: Scalars['ID'];
};


export type MutationInspectDoorArgs = {
  checklist: InspectDoorInput;
};


export type MutationInstallDoorArgs = {
  checklist: InstallDoorInput;
};


export type MutationInviteCompanyArgs = {
  companyInput: NewCompanyInput;
};


export type MutationMarkDoorForRepairArgs = {
  markDoorForRepairInput: MarkDoorForRepairInput;
};


export type MutationPasswordResetRequestArgs = {
  userEmail: Scalars['String'];
};


export type MutationRecordDoorNotAccessibleArgs = {
  details: RecordDoorNotAccessibleInput;
};


export type MutationRemoveContractorFromDoorsArgs = {
  contractorCompanyId: Scalars['ID'];
  ids: Array<Scalars['Float']>;
};


export type MutationRemoveFromMySitesArgs = {
  idListInput: IdListInput;
};


export type MutationRepairDoorArgs = {
  repairDoorInput: RepairDoorInput;
};


export type MutationReportInstallationArgs = {
  reportInstallationDetails: ReportDoorInstallationInput;
};


export type MutationRequestAccountArgs = {
  requestAccountInput: RequestAccountInput;
};


export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


export type MutationRetireDoorsArgs = {
  retireDoorInput: RetireDoorInput;
};


export type MutationSaveDoorModelArgs = {
  doorModelInput: DoorModelInput;
};


export type MutationSaveDoorModelDocumentsArgs = {
  doorModelDocumentsInput: DoorModelDocumentsInput;
};


export type MutationSignOffInspectionArgs = {
  doorId: Scalars['ID'];
  failureRiskId?: Maybe<Scalars['ID']>;
  isSuccess: Scalars['Boolean'];
  message: Scalars['String'];
  signOffDate?: Maybe<Scalars['DateTime']>;
};


export type MutationSignOffInstallationArgs = {
  doorId: Scalars['ID'];
  isSuccess: Scalars['Boolean'];
  message: Scalars['String'];
  signOffDate?: Maybe<Scalars['DateTime']>;
};


export type MutationStartInspectionArgs = {
  doorId: Scalars['ID'];
  inspectionFrequency?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  locationTypeId?: Maybe<Scalars['ID']>;
  siteId?: Maybe<Scalars['ID']>;
};


export type MutationStartInstallationArgs = {
  doorId: Scalars['ID'];
  location: Scalars['String'];
  locationTypeId?: Maybe<Scalars['ID']>;
  siteId: Scalars['ID'];
};


export type MutationTransferDoorsArgs = {
  destinationCompanyId?: Maybe<Scalars['ID']>;
  destinationCompanyName?: Maybe<Scalars['String']>;
  destinationUserEmail: Scalars['String'];
  ids: Array<Scalars['Float']>;
  orderRef: Scalars['String'];
  tagCompanyId?: Maybe<Scalars['ID']>;
  tagUserEmail?: Maybe<Scalars['String']>;
  transferType: TransferTypes;
};


export type MutationUpdateDoorArgs = {
  doorUpdate: DoorUpdateInput;
};


export type MutationUploadChecklistPhotoArgs = {
  doorId: Scalars['ID'];
  photoInput: ChecklistPhotoInput;
};


export type MutationUploadDoorsFileArgs = {
  file: Scalars['Upload'];
  siteId?: Maybe<Scalars['ID']>;
};

export type NbInspectionsDueByMonth = {
  data: Array<NbInspectionsInMonth>;
};

export type NbInspectionsInMonth = {
  month: Scalars['Int'];
  nbInspections: Scalars['Int'];
  year: Scalars['Int'];
};

export type NewCompanyInput = {
  adminEmail: Scalars['String'];
  adminName: Scalars['String'];
  billingAddressLine1: Scalars['String'];
  billingAddressLine2: Scalars['String'];
  billingCity: Scalars['String'];
  billingEmail: Scalars['String'];
  billingPostcode: Scalars['String'];
  canReportInstallations: Scalars['Boolean'];
  companyName: Scalars['String'];
  contactPhone: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  inspectionCertificateAdditionalText?: Maybe<Scalars['String']>;
  installationCertificateAdditionalText?: Maybe<Scalars['String']>;
  isT1Contractor: Scalars['Boolean'];
  photos?: Maybe<Array<DescriptedFileInput>>;
  typeIds: Array<Scalars['Float']>;
};

export type NewUserInput = {
  companyId: Scalars['Float'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  roleIds: Array<Scalars['Float']>;
};

export type PaginatedBillingEventsSummaryResponse = {
  hasMore: Scalars['Boolean'];
  items: Array<BillingEventsSummary>;
  total: Scalars['Int'];
};

export type PaginatedCompanyResponse = {
  hasMore: Scalars['Boolean'];
  items: Array<CompanyHeader>;
  total: Scalars['Int'];
};

export type PaginatedDoorModelResponse = {
  hasMore: Scalars['Boolean'];
  items: Array<DoorModel>;
  total: Scalars['Int'];
};

export type PaginatedDoorResponse = {
  hasMore: Scalars['Boolean'];
  items: Array<Door>;
  total: Scalars['Int'];
};

export type PaginatedSiteResponse = {
  hasMore: Scalars['Boolean'];
  items: Array<Site>;
  total: Scalars['Int'];
};

export type PaginatedUserResponse = {
  hasMore: Scalars['Boolean'];
  items: Array<User>;
  total: Scalars['Int'];
};

export type Query = {
  billingEventsSummary: PaginatedBillingEventsSummaryResponse;
  checklistAnswersByInspectionId: Array<ChecklistAnswerWithDocument>;
  checklistAnswersByInstallationId: Array<ChecklistAnswerWithDocument>;
  checklistQuestions: Array<ChecklistQuestion>;
  companies: PaginatedCompanyResponse;
  companiesByNameAndType: Array<Company>;
  company: Company;
  companyAdminsByName: Array<User>;
  companyBillingReport: FileDownloadResponse;
  companyTypes: Array<CompanyType>;
  companyUsersByEmail: Array<User>;
  dashboard: DashboardResponse;
  documentTypes: Array<DocumentType>;
  door: Door;
  doorByBarcode: Door;
  doorByTagId: Door;
  doorModel: DoorModel;
  doorModels: PaginatedDoorModelResponse;
  doorModelsByName: Array<DoorModel>;
  doors: PaginatedDoorResponse;
  doorsByIds: Array<Door>;
  doorsBySiteId: Array<Door>;
  failureRisks: Array<FailureRisk>;
  fdRatings: Array<FdRating>;
  fireStoppingTypes: Array<FireStoppingType>;
  inspectionsByDoorId: Array<Inspection>;
  inspectorInspectionActivity: FileDownloadResponse;
  installationsByDoorId: Array<Installation>;
  installerInstallationActivity: FileDownloadResponse;
  isBarcodeValid: Scalars['Boolean'];
  locationTypes: Array<LocationType>;
  loggedIn: User;
  mySites: PaginatedSiteResponse;
  ownerDoorState: FileDownloadResponse;
  ownerInspectionActivity: FileDownloadResponse;
  ownerInstallationActivity: FileDownloadResponse;
  roles: Array<Role>;
  rolesByCompany: Array<Role>;
  site: Site;
  sites: PaginatedSiteResponse;
  sitesByName: Array<Site>;
  sitesWithDoorsAssignedToCompany: Array<Site>;
  user: User;
  users: PaginatedUserResponse;
};


export type QueryBillingEventsSummaryArgs = {
  reportPeriodFrom?: Maybe<Scalars['DateTime']>;
  reportPeriodTo?: Maybe<Scalars['DateTime']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  textCriteria?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};


export type QueryChecklistAnswersByInspectionIdArgs = {
  inspectionId: Scalars['Float'];
};


export type QueryChecklistAnswersByInstallationIdArgs = {
  installationId: Scalars['Float'];
};


export type QueryChecklistQuestionsArgs = {
  code: Scalars['String'];
};


export type QueryCompaniesArgs = {
  active: Scalars['Boolean'];
  disabled: Scalars['Boolean'];
  reportPeriodFrom?: Maybe<Scalars['DateTime']>;
  reportPeriodTo?: Maybe<Scalars['DateTime']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  textCriteria?: Maybe<Scalars['String']>;
};


export type QueryCompaniesByNameAndTypeArgs = {
  isT1Contractor?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  type: Array<CompanyTypes>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyAdminsByNameArgs = {
  companyId: Scalars['ID'];
  name: Scalars['String'];
  take: Scalars['Int'];
};


export type QueryCompanyBillingReportArgs = {
  companyId: Scalars['ID'];
  reportPeriodFrom?: Maybe<Scalars['DateTime']>;
  reportPeriodTo?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
};


export type QueryCompanyUsersByEmailArgs = {
  companyId: Scalars['ID'];
  email: Scalars['String'];
  includeDeleted?: Maybe<Scalars['Boolean']>;
};


export type QueryDashboardArgs = {
  siteId?: Maybe<Scalars['ID']>;
};


export type QueryDoorArgs = {
  id: Scalars['ID'];
};


export type QueryDoorByBarcodeArgs = {
  barcode: Scalars['String'];
};


export type QueryDoorByTagIdArgs = {
  tagId: Scalars['String'];
};


export type QueryDoorModelArgs = {
  id: Scalars['ID'];
};


export type QueryDoorModelsArgs = {
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryDoorModelsByNameArgs = {
  name: Scalars['String'];
};


export type QueryDoorsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  doorsTextFilter?: Maybe<Scalars['String']>;
  excludeDoorsWithSite?: Maybe<Scalars['Boolean']>;
  excludeTransferred?: Maybe<Scalars['Boolean']>;
  inspectionFrom?: Maybe<Scalars['DateTime']>;
  inspectionTo?: Maybe<Scalars['DateTime']>;
  installationFrom?: Maybe<Scalars['DateTime']>;
  installationTo?: Maybe<Scalars['DateTime']>;
  locationTypes?: Maybe<Array<Scalars['String']>>;
  siteId?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  stage?: Maybe<DoorFilterStages>;
  statuses?: Maybe<Array<Scalars['String']>>;
  tagged?: Maybe<Scalars['Boolean']>;
  take?: Maybe<Scalars['Int']>;
  textFilter?: Maybe<Scalars['String']>;
};


export type QueryDoorsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryDoorsBySiteIdArgs = {
  siteId: Scalars['ID'];
  updatedSince?: Maybe<Scalars['DateTime']>;
};


export type QueryInspectionsByDoorIdArgs = {
  doorId: Scalars['ID'];
};


export type QueryInspectorInspectionActivityArgs = {
  reportPeriodFrom: Scalars['DateTime'];
  reportPeriodTo: Scalars['DateTime'];
  siteId?: Maybe<Scalars['ID']>;
};


export type QueryInstallationsByDoorIdArgs = {
  doorId: Scalars['ID'];
};


export type QueryInstallerInstallationActivityArgs = {
  reportPeriodFrom: Scalars['DateTime'];
  reportPeriodTo: Scalars['DateTime'];
  siteId?: Maybe<Scalars['ID']>;
};


export type QueryIsBarcodeValidArgs = {
  barcode: Scalars['String'];
  doorId?: Maybe<Scalars['ID']>;
};


export type QueryMySitesArgs = {
  searchTerm?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryOwnerDoorStateArgs = {
  siteId?: Maybe<Scalars['ID']>;
};


export type QueryOwnerInspectionActivityArgs = {
  reportPeriodFrom: Scalars['DateTime'];
  reportPeriodTo: Scalars['DateTime'];
  siteId?: Maybe<Scalars['ID']>;
};


export type QueryOwnerInstallationActivityArgs = {
  reportPeriodFrom: Scalars['DateTime'];
  reportPeriodTo: Scalars['DateTime'];
  siteId?: Maybe<Scalars['ID']>;
};


export type QueryRolesByCompanyArgs = {
  id: Scalars['ID'];
};


export type QuerySiteArgs = {
  id: Scalars['ID'];
};


export type QuerySitesArgs = {
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
  textFilter?: Maybe<Scalars['String']>;
};


export type QuerySitesByNameArgs = {
  name: Scalars['String'];
};


export type QuerySitesWithDoorsAssignedToCompanyArgs = {
  companyType: CompanyTypes;
};


export type QueryUserArgs = {
  id: Scalars['Float'];
};


export type QueryUsersArgs = {
  companyId: Scalars['ID'];
  includeDeleted: Scalars['Boolean'];
  skip?: Maybe<Scalars['Int']>;
  sortDirection?: Maybe<Scalars['String']>;
  sortField?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
};

export type RecordDoorNotAccessibleInput = {
  date: Scalars['DateTime'];
  doorId: Scalars['ID'];
  photos?: Maybe<Array<Scalars['Upload']>>;
  reason: Scalars['String'];
};

export type RepairDoorInput = {
  id: Scalars['Float'];
  photos?: Maybe<Array<Scalars['Upload']>>;
  reason: Scalars['String'];
};

export type ReportDoorInstallationInput = {
  doorId: Scalars['ID'];
  installationCertificate?: Maybe<Scalars['Upload']>;
  installationReport: Scalars['Upload'];
  installedAt: Scalars['DateTime'];
  location?: Maybe<Scalars['String']>;
  locationTypeId?: Maybe<Scalars['ID']>;
  signOffComments?: Maybe<Scalars['String']>;
};

export type RequestAccountInput = {
  adminEmail: Scalars['String'];
  adminName: Scalars['String'];
  companyName: Scalars['String'];
  installerAccreditingOrganisation?: Maybe<Scalars['String']>;
  installerRegistrationNumber?: Maybe<Scalars['String']>;
  typeIds: Array<Scalars['Float']>;
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type RetireDoorInput = {
  ids: Array<Scalars['Float']>;
  isMobileRetirement?: Maybe<Scalars['Boolean']>;
  photos?: Maybe<Array<Scalars['Upload']>>;
  reason: Scalars['String'];
};

export type Role = {
  code: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type Site = {
  addedBy?: Maybe<User>;
  addressOne?: Maybe<Scalars['String']>;
  addressTwo?: Maybe<Scalars['String']>;
  areas?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  documents?: Maybe<Array<Document>>;
  doorCount: Scalars['Float'];
  id: Scalars['ID'];
  installedDoorCount?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  ownedBy?: Maybe<Company>;
  postcode?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  siteContact?: Maybe<SiteContact>;
};


export type SiteDoorCountArgs = {
  companyType: CompanyTypes;
};

export type SiteContact = {
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mobileNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SiteContactInput = {
  emailAddress?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  mobileNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SiteInput = {
  addressOne?: Maybe<Scalars['String']>;
  addressTwo?: Maybe<Scalars['String']>;
  areas?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  contact?: Maybe<SiteContactInput>;
  drawings?: Maybe<Array<DescriptedFileInput>>;
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  owner?: Maybe<SiteOwnerInput>;
  photos?: Maybe<Array<DescriptedFileInput>>;
  postcode?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type SiteInspection = {
  due: Scalars['Int'];
  failed: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  noFreq: Scalars['Int'];
  overdue: Scalars['Int'];
  passed: Scalars['Int'];
};

export type SiteInspectionsOverview = {
  doors: Array<DoorInspectionOverview>;
};

export type SiteOwnerInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type Status = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type TempChecklistPhoto = {
  document: Document;
  door: Door;
  id: Scalars['ID'];
  photoUUID: Scalars['String'];
};

export enum TransferTypes {
  InstallerToFacilityManagement = 'InstallerToFacilityManagement',
  ManufacturerToInstaller = 'ManufacturerToInstaller'
}


export type User = {
  addedBy?: Maybe<User>;
  company?: Maybe<Company>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  password: Scalars['String'];
  passwordResetExpiry?: Maybe<Scalars['DateTime']>;
  roles: Array<Role>;
};

export type WorkType = {
  code: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type BillingEventsSummaryQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  reportPeriodFrom?: Maybe<Scalars['DateTime']>;
  reportPeriodTo?: Maybe<Scalars['DateTime']>;
  textCriteria?: Maybe<Scalars['String']>;
  type: Scalars['String'];
}>;


export type BillingEventsSummaryQuery = { billingEventsSummary: (
    Pick<PaginatedBillingEventsSummaryResponse, 'total' | 'hasMore'>
    & { items: Array<Pick<BillingEventsSummary, 'companyId' | 'companyName' | 'billingEmail' | 'installations' | 'installationsReported' | 'inspections' | 'doorCount'>> }
  ) };

export type CompanyBillingReportQueryVariables = Exact<{
  companyId: Scalars['ID'];
  reportPeriodFrom?: Maybe<Scalars['DateTime']>;
  reportPeriodTo?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
}>;


export type CompanyBillingReportQuery = { companyBillingReport: Pick<FileDownloadResponse, 'path'> };

export type CompaniesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  disabled: Scalars['Boolean'];
  reportPeriodFrom?: Maybe<Scalars['DateTime']>;
  reportPeriodTo?: Maybe<Scalars['DateTime']>;
  textCriteria?: Maybe<Scalars['String']>;
}>;


export type CompaniesQuery = { companies: (
    Pick<PaginatedCompanyResponse, 'total' | 'hasMore'>
    & { items: Array<Pick<CompanyHeader, 'id' | 'name' | 'billingEmail' | 'companyTypes' | 'status' | 'passedReports'>> }
  ) };

export type CompaniesByNameAndTypeQueryVariables = Exact<{
  name: Scalars['String'];
  type: Array<CompanyTypes>;
  isT1Contractor?: Maybe<Scalars['Boolean']>;
}>;


export type CompaniesByNameAndTypeQuery = { companiesByNameAndType: Array<Pick<Company, 'id' | 'name'>> };

export type CompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyQuery = { company: (
    Pick<Company, 'id' | 'name' | 'contactPhone' | 'billingEmail' | 'billingAddressOne' | 'billingAddressTwo' | 'billingCity' | 'billingPostcode' | 'isT1Contractor' | 'passedReportsForCurrentMonth' | 'apiKey' | 'installationCertificateAdditionalText' | 'inspectionCertificateAdditionalText' | 'canReportInstallations'>
    & { types: Array<Pick<CompanyType, 'id' | 'code' | 'name'>>, admin?: Maybe<Pick<User, 'fullName' | 'email'>>, documents?: Maybe<Array<(
      Pick<Document, 'id' | 'originalFilename' | 'description'>
      & { documentType: Pick<DocumentType, 'id' | 'code' | 'name'> }
    )>> }
  ) };

export type GenerateApiKeyMutationVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type GenerateApiKeyMutation = Pick<Mutation, 'generateApiKey'>;

export type DashboardNbDoorsKpiQueryVariables = Exact<{
  siteId?: Maybe<Scalars['ID']>;
}>;


export type DashboardNbDoorsKpiQuery = { dashboard: Pick<DashboardResponse, 'nbDoors' | 'nbDoorsAddedThisMonth'> };

export type DashboardNbInspectionsDueThisMonthKpiQueryVariables = Exact<{
  siteId?: Maybe<Scalars['ID']>;
}>;


export type DashboardNbInspectionsDueThisMonthKpiQuery = { dashboard: Pick<DashboardResponse, 'nbInspectionsDueThisMonth'> };

export type DashboardNbInspectionsFailedLast30DaysKpiQueryVariables = Exact<{
  siteId?: Maybe<Scalars['ID']>;
}>;


export type DashboardNbInspectionsFailedLast30DaysKpiQuery = { dashboard: Pick<DashboardResponse, 'nbInspectionsFailedLast30Days' | 'nbInspectionsFailedPrevious30Days'> };

export type DashboardAvgRepairTimeLast30DaysKpiQueryVariables = Exact<{
  siteId?: Maybe<Scalars['ID']>;
}>;


export type DashboardAvgRepairTimeLast30DaysKpiQuery = { dashboard: Pick<DashboardResponse, 'avgRepairMinutesLast30Days' | 'avgRepairMinutesPrevious30Days'> };

export type DashboardNbInspectionsDueByMonthQueryVariables = Exact<{
  siteId?: Maybe<Scalars['ID']>;
}>;


export type DashboardNbInspectionsDueByMonthQuery = { dashboard: { nbInspectionsDueByMonth?: Maybe<{ data: Array<Pick<NbInspectionsInMonth, 'month' | 'year' | 'nbInspections'>> }> } };

export type DashboardAccountInspectionsOverviewQueryVariables = {};


export type DashboardAccountInspectionsOverviewQuery = { dashboard: { accountInspectionsOverview: { sites: Array<Pick<SiteInspection, 'id' | 'name' | 'passed' | 'failed' | 'due' | 'overdue' | 'noFreq'>> } } };

export type DashboardSiteInspectionsOverviewQueryVariables = Exact<{
  siteId?: Maybe<Scalars['ID']>;
}>;


export type DashboardSiteInspectionsOverviewQuery = { dashboard: { siteInspectionsOverview?: Maybe<{ doors: Array<Pick<DoorInspectionOverview, 'doorId' | 'locationOrDoorRef' | 'inspectionOverviewStatus' | 'doorStatusCode'>> }> } };

export type DoorsQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Scalars['String']>>;
  textFilter?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<DoorFilterStages>;
  excludeDoorsWithSite?: Maybe<Scalars['Boolean']>;
}>;


export type DoorsQuery = { doors: (
    Pick<PaginatedDoorResponse, 'total' | 'hasMore'>
    & { items: Array<(
      Pick<Door, 'id' | 'projectReference' | 'doorReference' | 'tagId' | 'partsListComplete' | 'fireCertificateComplete' | 'installationGuideComplete' | 'warrantyComplete' | 'deletedAt' | 'transferredToInstallerAt'>
      & { model?: Maybe<Pick<DoorModel, 'id' | 'name' | 'partsListComplete' | 'fireCertificateComplete' | 'installationGuideComplete' | 'warrantyComplete'>>, status: Pick<Status, 'name' | 'code'>, installer?: Maybe<Pick<Company, 'id'>>, site?: Maybe<Pick<Site, 'id'>> }
    )> }
  ) };

export type DoorHistoryFragment = (
  Pick<DoorHistory, 'id' | 'createdAt' | 'addedByName' | 'addedByCompanyName' | 'additionalInfoJson'>
  & { historyEventType: Pick<HistoryEventType, 'id' | 'name' | 'code'> }
);

export type DoorFragment = (
  Pick<Door, 'id' | 'projectReference' | 'doorReference' | 'tagId' | 'barcode' | 'lineBarcode' | 'inspectionFrequency' | 'nextInspectionDate' | 'leaf1AccousticRating' | 'leaf1CoreSupplier' | 'leaf1Width' | 'leaf1Height' | 'leaf2AccousticRating' | 'leaf2CoreSupplier' | 'leaf2Width' | 'leaf2Height' | 'readOnly'>
  & { status: Pick<Status, 'code'>, fdRating?: Maybe<Pick<FdRating, 'id' | 'value'>>, documents?: Maybe<Array<(
    Pick<Document, 'id' | 'originalFilename' | 'description'>
    & { documentType: Pick<DocumentType, 'id' | 'code' | 'name'> }
  )>>, model?: Maybe<(
    { documents?: Maybe<Array<(
      Pick<Document, 'id' | 'originalFilename' | 'description'>
      & { documentType: Pick<DocumentType, 'id' | 'code' | 'name'> }
    )>> }
    & DoorModelFragment
  )>, doorParts?: Maybe<Pick<DoorParts, 'lockLatch' | 'hinges' | 'closer' | 'spyhole' | 'letterbox' | 'thresholdStrip' | 'dropSeal' | 'handles' | 'smokeSeals' | 'cylinder' | 'intumescentStrip' | 'keeps' | 'numerals' | 'chain' | 'weatherSeals' | 'additionalComments'>>, history?: Maybe<Array<DoorHistoryFragment>> }
);

export type DoorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DoorQuery = { door: DoorFragment };

export type DoorByTagIdQueryVariables = Exact<{
  tagId: Scalars['String'];
}>;


export type DoorByTagIdQuery = { doorByTagId: DoorFragment };

export type DoorsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type DoorsByIdsQuery = { doorsByIds: Array<(
    Pick<Door, 'id' | 'tagId' | 'projectReference' | 'doorReference'>
    & { model?: Maybe<Pick<DoorModel, 'id' | 'name'>> }
  )> };

export type DoorLocationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DoorLocationQuery = { door: (
    Pick<Door, 'id' | 'location'>
    & { locationType?: Maybe<Pick<LocationType, 'id'>> }
  ) };

export type IsBarcodeValidQueryVariables = Exact<{
  doorId?: Maybe<Scalars['ID']>;
  barcode: Scalars['String'];
}>;


export type IsBarcodeValidQuery = Pick<Query, 'isBarcodeValid'>;

export type AssignDoorsToSiteMutationVariables = Exact<{
  doorsInput: Array<AssignDoorToSiteInput>;
  siteId: Scalars['ID'];
}>;


export type AssignDoorsToSiteMutation = Pick<Mutation, 'assignDoorsToSite'>;

export type DisableDoorsMutationVariables = Exact<{
  idListInput: IdListInput;
}>;


export type DisableDoorsMutation = Pick<Mutation, 'disableDoors'>;

export type UploadDoorsFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  siteId?: Maybe<Scalars['ID']>;
}>;


export type UploadDoorsFileMutation = Pick<Mutation, 'uploadDoorsFile'>;

export type AddDoorMutationVariables = Exact<{
  door: DoorInput;
}>;


export type AddDoorMutation = { addDoor: Pick<Door, 'id'> };

export type EditDoorMutationVariables = Exact<{
  door: DoorInput;
}>;


export type EditDoorMutation = { addDoor: DoorFragment };

export type TransferDoorsMutationVariables = Exact<{
  ids: Array<Scalars['Float']>;
  destinationCompanyId?: Maybe<Scalars['ID']>;
  destinationCompanyName?: Maybe<Scalars['String']>;
  destinationUserEmail: Scalars['String'];
  transferType: TransferTypes;
  orderRef: Scalars['String'];
  tagCompanyId?: Maybe<Scalars['ID']>;
  tagUserEmail?: Maybe<Scalars['String']>;
}>;


export type TransferDoorsMutation = Pick<Mutation, 'transferDoors'>;

export type AssignDoorsToContractorMutationVariables = Exact<{
  ids: Array<Scalars['Float']>;
  destinationCompanyId: Scalars['ID'];
  destinationUserEmail: Scalars['String'];
  workTypeCode: Scalars['String'];
}>;


export type AssignDoorsToContractorMutation = Pick<Mutation, 'assignDoorsToContractor'>;

export type RemoveContractorFromDoorsMutationVariables = Exact<{
  ids: Array<Scalars['Float']>;
  contractorCompanyId: Scalars['ID'];
}>;


export type RemoveContractorFromDoorsMutation = Pick<Mutation, 'removeContractorFromDoors'>;

export type RetireDoorsMutationVariables = Exact<{
  retireDoorInput: RetireDoorInput;
}>;


export type RetireDoorsMutation = Pick<Mutation, 'retireDoors'>;

export type MarkDoorForRepairMutationVariables = Exact<{
  markDoorForRepairInput: MarkDoorForRepairInput;
}>;


export type MarkDoorForRepairMutation = Pick<Mutation, 'markDoorForRepair'>;

export type DoorModelPartsFragment = Pick<DoorModelParts, 'id' | 'lockLatch' | 'closer' | 'intumescentStrip' | 'hinges' | 'handles' | 'smokeSeals' | 'dropSeal' | 'cylinder' | 'letterbox' | 'spyhole' | 'thresholdStrip' | 'additionalComments'>;

export type DoorModelFragment = (
  Pick<DoorModel, 'id' | 'name' | 'coreSupplier'>
  & { fdRating?: Maybe<Pick<FdRating, 'id' | 'value'>>, doorModelParts?: Maybe<DoorModelPartsFragment> }
);

export type DoorModelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DoorModelQuery = { doorModel: (
    { documents?: Maybe<Array<(
      Pick<Document, 'id' | 'originalFilename' | 'description'>
      & { documentType: Pick<DocumentType, 'id' | 'code' | 'name'> }
    )>> }
    & DoorModelFragment
  ) };

export type DoorModelsQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
}>;


export type DoorModelsQuery = { doorModels: (
    Pick<PaginatedDoorModelResponse, 'total' | 'hasMore'>
    & { items: Array<(
      Pick<DoorModel, 'id' | 'name' | 'coreSupplier' | 'partsListComplete' | 'fireCertificateComplete' | 'installationGuideComplete' | 'warrantyComplete'>
      & { fdRating?: Maybe<Pick<FdRating, 'id' | 'value'>> }
    )> }
  ) };

export type SaveDoorModelMutationVariables = Exact<{
  doorModelInput: DoorModelInput;
}>;


export type SaveDoorModelMutation = Pick<Mutation, 'saveDoorModel'>;

export type DeleteDoorModelsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type DeleteDoorModelsMutation = Pick<Mutation, 'deleteDoorModels'>;

export type SaveDoorModelDocumentsMutationVariables = Exact<{
  doorModelDocumentsInput: DoorModelDocumentsInput;
}>;


export type SaveDoorModelDocumentsMutation = { saveDoorModelDocuments: (
    { documents?: Maybe<Array<(
      Pick<Document, 'id' | 'originalFilename' | 'description'>
      & { documentType: Pick<DocumentType, 'id' | 'code' | 'name'> }
    )>> }
    & DoorModelFragment
  ) };

export type DoorModelsByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type DoorModelsByNameQuery = { doorModelsByName: Array<Pick<DoorModel, 'id' | 'name'>> };

export type EditCompanyMutationVariables = Exact<{
  companyInput: NewCompanyInput;
}>;


export type EditCompanyMutation = { editCompany: Pick<Company, 'id'> };

export type EditSiteMutationVariables = Exact<{
  siteInput: SiteInput;
}>;


export type EditSiteMutation = { editSite: Pick<Site, 'id'> };

export type ReportInstallationMutationVariables = Exact<{
  reportInstallationDetails: ReportDoorInstallationInput;
}>;


export type ReportInstallationMutation = Pick<Mutation, 'reportInstallation'>;

export type InviteCompanyMutationVariables = Exact<{
  companyInput: NewCompanyInput;
}>;


export type InviteCompanyMutation = { inviteCompany: Pick<Company, 'id'> };

export type LocationTypesQueryVariables = {};


export type LocationTypesQuery = { locationTypes: Array<Pick<LocationType, 'id' | 'code' | 'name'>> };

export type CompanyTypeFragment = Pick<CompanyType, 'id' | 'code' | 'name'>;

export type CompanyTypesQueryVariables = {};


export type CompanyTypesQuery = { companyTypes: Array<CompanyTypeFragment> };

export type DocumentTypesQueryVariables = {};


export type DocumentTypesQuery = { documentTypes: Array<Pick<DocumentType, 'id' | 'code' | 'name'>> };

export type FdRatingsQueryVariables = {};


export type FdRatingsQuery = { fdRatings: Array<Pick<FdRating, 'value' | 'id'>> };

export type RemoveFromMySitesMutationVariables = Exact<{
  idListInput: IdListInput;
}>;


export type RemoveFromMySitesMutation = Pick<Mutation, 'removeFromMySites'>;

export type AddToMySitesMutationVariables = Exact<{
  idListInput: IdListInput;
}>;


export type AddToMySitesMutation = Pick<Mutation, 'addToMySites'>;

export type MySitesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
}>;


export type MySitesQuery = { mySites: (
    Pick<PaginatedSiteResponse, 'total' | 'hasMore'>
    & { items: Array<(
      Pick<Site, 'id' | 'name' | 'reference' | 'areas' | 'addressOne' | 'addressTwo' | 'city' | 'postcode' | 'installedDoorCount'>
      & { ownedBy?: Maybe<Pick<Company, 'id' | 'name'>> }
    )> }
  ) };

export type MySitesHeaderQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
}>;


export type MySitesHeaderQuery = { mySites: (
    Pick<PaginatedSiteResponse, 'total' | 'hasMore'>
    & { items: Array<(
      Pick<Site, 'id' | 'name'>
      & { ownedBy?: Maybe<Pick<Company, 'id'>> }
    )> }
  ) };

export type OwnerInspectionActivityQueryVariables = Exact<{
  reportPeriodFrom: Scalars['DateTime'];
  reportPeriodTo: Scalars['DateTime'];
  siteId?: Maybe<Scalars['ID']>;
}>;


export type OwnerInspectionActivityQuery = { ownerInspectionActivity: Pick<FileDownloadResponse, 'path'> };

export type OwnerDoorStateQueryVariables = Exact<{
  siteId?: Maybe<Scalars['ID']>;
}>;


export type OwnerDoorStateQuery = { ownerDoorState: Pick<FileDownloadResponse, 'path'> };

export type InspectorInspectionActivityQueryVariables = Exact<{
  reportPeriodFrom: Scalars['DateTime'];
  reportPeriodTo: Scalars['DateTime'];
  siteId?: Maybe<Scalars['ID']>;
}>;


export type InspectorInspectionActivityQuery = { inspectorInspectionActivity: Pick<FileDownloadResponse, 'path'> };

export type InstallerInstallationActivityQueryVariables = Exact<{
  reportPeriodFrom: Scalars['DateTime'];
  reportPeriodTo: Scalars['DateTime'];
  siteId?: Maybe<Scalars['ID']>;
}>;


export type InstallerInstallationActivityQuery = { installerInstallationActivity: Pick<FileDownloadResponse, 'path'> };

export type OwnerInstallationActivityQueryVariables = Exact<{
  reportPeriodFrom: Scalars['DateTime'];
  reportPeriodTo: Scalars['DateTime'];
  siteId?: Maybe<Scalars['ID']>;
}>;


export type OwnerInstallationActivityQuery = { ownerInstallationActivity: Pick<FileDownloadResponse, 'path'> };

export type RequestAccountMutationVariables = Exact<{
  requestAccountInput: RequestAccountInput;
}>;


export type RequestAccountMutation = Pick<Mutation, 'requestAccount'>;

export type RolesByCompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RolesByCompanyQuery = { rolesByCompany: Array<Pick<Role, 'id' | 'code' | 'name'>> };

export type RoleFragment = Pick<Role, 'id' | 'code' | 'name'>;

export type RolesQueryVariables = {};


export type RolesQuery = { roles: Array<Pick<Role, 'id' | 'code' | 'name'>> };

export type SiteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SiteQuery = { site: (
    Pick<Site, 'id' | 'name' | 'reference' | 'areas' | 'addressOne' | 'addressTwo' | 'city' | 'postcode'>
    & { ownedBy?: Maybe<Pick<Company, 'id' | 'name'>>, siteContact?: Maybe<Pick<SiteContact, 'id' | 'name' | 'phoneNumber' | 'mobileNumber' | 'emailAddress'>>, documents?: Maybe<Array<(
      Pick<Document, 'id' | 'originalFilename' | 'description'>
      & { documentType: Pick<DocumentType, 'id' | 'code' | 'name'> }
    )>> }
  ) };

export type SiteDoorsQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  statuses?: Maybe<Array<Scalars['String']>>;
  active?: Maybe<Scalars['Boolean']>;
  siteId?: Maybe<Scalars['ID']>;
  withActiveAssignments: Scalars['Boolean'];
  installationFrom?: Maybe<Scalars['DateTime']>;
  installationTo?: Maybe<Scalars['DateTime']>;
  inspectionFrom?: Maybe<Scalars['DateTime']>;
  inspectionTo?: Maybe<Scalars['DateTime']>;
  doorsTextFilter?: Maybe<Scalars['String']>;
  locationTypes?: Maybe<Array<Scalars['String']>>;
}>;


export type SiteDoorsQuery = { doors: (
    Pick<PaginatedDoorResponse, 'total' | 'hasMore'>
    & { items: Array<(
      Pick<Door, 'id' | 'locationOrDoorRef' | 'tagId' | 'latestInstallationDate' | 'lastestInstallationInstalledBy' | 'nextInspectionDate' | 'transferredToFacilityManagementAt' | 'inspectionFrequency'>
      & { status: Pick<Status, 'name' | 'code'>, activeDoorAssignments?: Maybe<Array<{ contractorCompany: Pick<Company, 'id' | 'name'>, doorAssignmentType: Pick<DoorAssignmentType, 'code'>, workType: Pick<WorkType, 'code' | 'name'> }>> }
    )> }
  ) };

export type SitesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  textFilter?: Maybe<Scalars['String']>;
}>;


export type SitesQuery = { sites: (
    Pick<PaginatedSiteResponse, 'total' | 'hasMore'>
    & { items: Array<(
      Pick<Site, 'id' | 'name' | 'reference' | 'areas' | 'addressOne' | 'addressTwo' | 'city' | 'postcode' | 'installedDoorCount'>
      & { ownedBy?: Maybe<Pick<Company, 'id' | 'name'>> }
    )> }
  ) };

export type AddSiteMutationVariables = Exact<{
  siteInput: SiteInput;
}>;


export type AddSiteMutation = Pick<Mutation, 'addSite'>;

export type UserQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type UserQuery = { user: (
    Pick<User, 'email' | 'id' | 'fullName'>
    & { roles: Array<RoleFragment> }
  ) };

export type LoggedInQueryVariables = {};


export type LoggedInQuery = { loggedIn: (
    Pick<User, 'id' | 'fullName' | 'email'>
    & { company?: Maybe<Pick<Company, 'id' | 'name' | 'canReportInstallations'>>, roles: Array<RoleFragment> }
  ) };

export type AcceptInviteMutationVariables = Exact<{
  acceptInviteInput: AcceptInviteInput;
}>;


export type AcceptInviteMutation = { acceptInvite: Array<Pick<Role, 'code'>> };

export type DisableUsersMutationVariables = Exact<{
  idListInput: IdListInput;
}>;


export type DisableUsersMutation = Pick<Mutation, 'disableUsers'>;

export type EnableUsersMutationVariables = Exact<{
  idListInput: IdListInput;
}>;


export type EnableUsersMutation = Pick<Mutation, 'enableUsers'>;

export type AddUserMutationVariables = Exact<{
  newUserInput: NewUserInput;
}>;


export type AddUserMutation = { addUser: Pick<User, 'id'> };

export type PasswordResetRequestMutationVariables = Exact<{
  userEmail: Scalars['String'];
}>;


export type PasswordResetRequestMutation = Pick<Mutation, 'passwordResetRequest'>;

export type ResetPasswordMutationVariables = Exact<{
  resetPasswordInput: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { resetPassword: Array<Pick<Role, 'code'>> };

export type EditUserMutationVariables = Exact<{
  editUserInput: EditUserInput;
}>;


export type EditUserMutation = Pick<Mutation, 'editUser'>;

export type UsersQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  sortField?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<Scalars['String']>;
  companyId: Scalars['ID'];
  includeDeleted: Scalars['Boolean'];
}>;


export type UsersQuery = { users: (
    Pick<PaginatedUserResponse, 'total' | 'hasMore'>
    & { items: Array<(
      Pick<User, 'id' | 'fullName' | 'email' | 'deletedAt'>
      & { addedBy?: Maybe<(
        Pick<User, 'fullName'>
        & { roles: Array<Pick<Role, 'name'>> }
      )>, roles: Array<RoleFragment> }
    )> }
  ) };

export type CompanyUsersByEmailQueryVariables = Exact<{
  email: Scalars['String'];
  companyId: Scalars['ID'];
  includeDeleted?: Maybe<Scalars['Boolean']>;
}>;


export type CompanyUsersByEmailQuery = { companyUsersByEmail: Array<Pick<User, 'id' | 'email'>> };

export type CompanyAdminsByNameQueryVariables = Exact<{
  name: Scalars['String'];
  companyId: Scalars['ID'];
  take: Scalars['Int'];
}>;


export type CompanyAdminsByNameQuery = { companyAdminsByName: Array<Pick<User, 'id' | 'fullName' | 'email'>> };

export const DoorModelPartsFragmentDoc = gql`
    fragment DoorModelParts on DoorModelParts {
  id
  lockLatch
  closer
  intumescentStrip
  hinges
  handles
  smokeSeals
  dropSeal
  cylinder
  letterbox
  spyhole
  thresholdStrip
  additionalComments
}
    `;
export const DoorModelFragmentDoc = gql`
    fragment DoorModel on DoorModel {
  id
  name
  coreSupplier
  fdRating {
    id
    value
  }
  doorModelParts {
    ...DoorModelParts
  }
}
    ${DoorModelPartsFragmentDoc}`;
export const DoorHistoryFragmentDoc = gql`
    fragment DoorHistory on DoorHistory {
  id
  createdAt
  addedByName
  addedByCompanyName
  additionalInfoJson
  historyEventType {
    id
    name
    code
  }
}
    `;
export const DoorFragmentDoc = gql`
    fragment Door on Door {
  id
  projectReference
  doorReference
  tagId
  barcode
  lineBarcode
  inspectionFrequency
  nextInspectionDate
  leaf1AccousticRating
  leaf1CoreSupplier
  leaf1Width
  leaf1Height
  leaf2AccousticRating
  leaf2CoreSupplier
  leaf2Width
  leaf2Height
  readOnly
  status {
    code
  }
  fdRating {
    id
    value
  }
  documents {
    id
    originalFilename
    description
    documentType {
      id
      code
      name
    }
  }
  model {
    ...DoorModel
    documents {
      id
      originalFilename
      description
      documentType {
        id
        code
        name
      }
    }
  }
  doorParts {
    lockLatch
    hinges
    closer
    spyhole
    letterbox
    thresholdStrip
    dropSeal
    handles
    smokeSeals
    cylinder
    intumescentStrip
    keeps
    numerals
    chain
    weatherSeals
    additionalComments
  }
  history {
    ...DoorHistory
  }
}
    ${DoorModelFragmentDoc}
${DoorHistoryFragmentDoc}`;
export const CompanyTypeFragmentDoc = gql`
    fragment CompanyType on CompanyType {
  id
  code
  name
}
    `;
export const RoleFragmentDoc = gql`
    fragment Role on Role {
  id
  code
  name
}
    `;
export const BillingEventsSummaryDocument = gql`
    query billingEventsSummary($skip: Int, $take: Int, $sortField: String, $sortDirection: String, $reportPeriodFrom: DateTime, $reportPeriodTo: DateTime, $textCriteria: String, $type: String!) {
  billingEventsSummary(skip: $skip, take: $take, sortField: $sortField, sortDirection: $sortDirection, reportPeriodFrom: $reportPeriodFrom, reportPeriodTo: $reportPeriodTo, textCriteria: $textCriteria, type: $type) {
    items {
      companyId
      companyName
      billingEmail
      installations
      installationsReported
      inspections
      doorCount
    }
    total
    hasMore
  }
}
    `;

/**
 * __useBillingEventsSummaryQuery__
 *
 * To run a query within a React component, call `useBillingEventsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingEventsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingEventsSummaryQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *      reportPeriodFrom: // value for 'reportPeriodFrom'
 *      reportPeriodTo: // value for 'reportPeriodTo'
 *      textCriteria: // value for 'textCriteria'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useBillingEventsSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BillingEventsSummaryQuery, BillingEventsSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<BillingEventsSummaryQuery, BillingEventsSummaryQueryVariables>(BillingEventsSummaryDocument, baseOptions);
      }
export function useBillingEventsSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BillingEventsSummaryQuery, BillingEventsSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BillingEventsSummaryQuery, BillingEventsSummaryQueryVariables>(BillingEventsSummaryDocument, baseOptions);
        }
export type BillingEventsSummaryQueryHookResult = ReturnType<typeof useBillingEventsSummaryQuery>;
export type BillingEventsSummaryLazyQueryHookResult = ReturnType<typeof useBillingEventsSummaryLazyQuery>;
export type BillingEventsSummaryQueryResult = ApolloReactCommon.QueryResult<BillingEventsSummaryQuery, BillingEventsSummaryQueryVariables>;
export const CompanyBillingReportDocument = gql`
    query companyBillingReport($companyId: ID!, $reportPeriodFrom: DateTime, $reportPeriodTo: DateTime, $type: String!) {
  companyBillingReport(companyId: $companyId, reportPeriodFrom: $reportPeriodFrom, reportPeriodTo: $reportPeriodTo, type: $type) {
    path
  }
}
    `;

/**
 * __useCompanyBillingReportQuery__
 *
 * To run a query within a React component, call `useCompanyBillingReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyBillingReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyBillingReportQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      reportPeriodFrom: // value for 'reportPeriodFrom'
 *      reportPeriodTo: // value for 'reportPeriodTo'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCompanyBillingReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyBillingReportQuery, CompanyBillingReportQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyBillingReportQuery, CompanyBillingReportQueryVariables>(CompanyBillingReportDocument, baseOptions);
      }
export function useCompanyBillingReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyBillingReportQuery, CompanyBillingReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyBillingReportQuery, CompanyBillingReportQueryVariables>(CompanyBillingReportDocument, baseOptions);
        }
export type CompanyBillingReportQueryHookResult = ReturnType<typeof useCompanyBillingReportQuery>;
export type CompanyBillingReportLazyQueryHookResult = ReturnType<typeof useCompanyBillingReportLazyQuery>;
export type CompanyBillingReportQueryResult = ApolloReactCommon.QueryResult<CompanyBillingReportQuery, CompanyBillingReportQueryVariables>;
export const CompaniesDocument = gql`
    query companies($skip: Int, $take: Int, $sortField: String, $sortDirection: String, $active: Boolean!, $disabled: Boolean!, $reportPeriodFrom: DateTime, $reportPeriodTo: DateTime, $textCriteria: String) {
  companies(skip: $skip, take: $take, sortField: $sortField, sortDirection: $sortDirection, active: $active, disabled: $disabled, reportPeriodFrom: $reportPeriodFrom, reportPeriodTo: $reportPeriodTo, textCriteria: $textCriteria) {
    items {
      id
      name
      billingEmail
      companyTypes
      status
      passedReports
    }
    total
    hasMore
  }
}
    `;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *      active: // value for 'active'
 *      disabled: // value for 'disabled'
 *      reportPeriodFrom: // value for 'reportPeriodFrom'
 *      reportPeriodTo: // value for 'reportPeriodTo'
 *      textCriteria: // value for 'textCriteria'
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, baseOptions);
      }
export function useCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, baseOptions);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = ApolloReactCommon.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CompaniesByNameAndTypeDocument = gql`
    query companiesByNameAndType($name: String!, $type: [CompanyTypes!]!, $isT1Contractor: Boolean) {
  companiesByNameAndType(name: $name, type: $type, isT1Contractor: $isT1Contractor) {
    id
    name
  }
}
    `;

/**
 * __useCompaniesByNameAndTypeQuery__
 *
 * To run a query within a React component, call `useCompaniesByNameAndTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesByNameAndTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesByNameAndTypeQuery({
 *   variables: {
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      isT1Contractor: // value for 'isT1Contractor'
 *   },
 * });
 */
export function useCompaniesByNameAndTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompaniesByNameAndTypeQuery, CompaniesByNameAndTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<CompaniesByNameAndTypeQuery, CompaniesByNameAndTypeQueryVariables>(CompaniesByNameAndTypeDocument, baseOptions);
      }
export function useCompaniesByNameAndTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompaniesByNameAndTypeQuery, CompaniesByNameAndTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompaniesByNameAndTypeQuery, CompaniesByNameAndTypeQueryVariables>(CompaniesByNameAndTypeDocument, baseOptions);
        }
export type CompaniesByNameAndTypeQueryHookResult = ReturnType<typeof useCompaniesByNameAndTypeQuery>;
export type CompaniesByNameAndTypeLazyQueryHookResult = ReturnType<typeof useCompaniesByNameAndTypeLazyQuery>;
export type CompaniesByNameAndTypeQueryResult = ApolloReactCommon.QueryResult<CompaniesByNameAndTypeQuery, CompaniesByNameAndTypeQueryVariables>;
export const CompanyDocument = gql`
    query company($id: ID!) {
  company(id: $id) {
    id
    name
    types {
      id
      code
      name
    }
    admin {
      fullName
      email
    }
    contactPhone
    billingEmail
    billingAddressOne
    billingAddressTwo
    billingCity
    billingPostcode
    isT1Contractor
    passedReportsForCurrentMonth
    apiKey
    documents {
      id
      originalFilename
      description
      documentType {
        id
        code
        name
      }
    }
    installationCertificateAdditionalText
    inspectionCertificateAdditionalText
    canReportInstallations
  }
}
    `;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, baseOptions);
      }
export function useCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, baseOptions);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = ApolloReactCommon.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const GenerateApiKeyDocument = gql`
    mutation generateApiKey($companyId: ID!) {
  generateApiKey(companyId: $companyId)
}
    `;
export type GenerateApiKeyMutationFn = ApolloReactCommon.MutationFunction<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;

/**
 * __useGenerateApiKeyMutation__
 *
 * To run a mutation, you first call `useGenerateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateApiKeyMutation, { data, loading, error }] = useGenerateApiKeyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGenerateApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>(GenerateApiKeyDocument, baseOptions);
      }
export type GenerateApiKeyMutationHookResult = ReturnType<typeof useGenerateApiKeyMutation>;
export type GenerateApiKeyMutationResult = ApolloReactCommon.MutationResult<GenerateApiKeyMutation>;
export type GenerateApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;
export const DashboardNbDoorsKpiDocument = gql`
    query dashboardNbDoorsKpi($siteId: ID) {
  dashboard(siteId: $siteId) {
    nbDoors
    nbDoorsAddedThisMonth
  }
}
    `;

/**
 * __useDashboardNbDoorsKpiQuery__
 *
 * To run a query within a React component, call `useDashboardNbDoorsKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardNbDoorsKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardNbDoorsKpiQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useDashboardNbDoorsKpiQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardNbDoorsKpiQuery, DashboardNbDoorsKpiQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardNbDoorsKpiQuery, DashboardNbDoorsKpiQueryVariables>(DashboardNbDoorsKpiDocument, baseOptions);
      }
export function useDashboardNbDoorsKpiLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardNbDoorsKpiQuery, DashboardNbDoorsKpiQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardNbDoorsKpiQuery, DashboardNbDoorsKpiQueryVariables>(DashboardNbDoorsKpiDocument, baseOptions);
        }
export type DashboardNbDoorsKpiQueryHookResult = ReturnType<typeof useDashboardNbDoorsKpiQuery>;
export type DashboardNbDoorsKpiLazyQueryHookResult = ReturnType<typeof useDashboardNbDoorsKpiLazyQuery>;
export type DashboardNbDoorsKpiQueryResult = ApolloReactCommon.QueryResult<DashboardNbDoorsKpiQuery, DashboardNbDoorsKpiQueryVariables>;
export const DashboardNbInspectionsDueThisMonthKpiDocument = gql`
    query dashboardNbInspectionsDueThisMonthKpi($siteId: ID) {
  dashboard(siteId: $siteId) {
    nbInspectionsDueThisMonth
  }
}
    `;

/**
 * __useDashboardNbInspectionsDueThisMonthKpiQuery__
 *
 * To run a query within a React component, call `useDashboardNbInspectionsDueThisMonthKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardNbInspectionsDueThisMonthKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardNbInspectionsDueThisMonthKpiQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useDashboardNbInspectionsDueThisMonthKpiQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardNbInspectionsDueThisMonthKpiQuery, DashboardNbInspectionsDueThisMonthKpiQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardNbInspectionsDueThisMonthKpiQuery, DashboardNbInspectionsDueThisMonthKpiQueryVariables>(DashboardNbInspectionsDueThisMonthKpiDocument, baseOptions);
      }
export function useDashboardNbInspectionsDueThisMonthKpiLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardNbInspectionsDueThisMonthKpiQuery, DashboardNbInspectionsDueThisMonthKpiQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardNbInspectionsDueThisMonthKpiQuery, DashboardNbInspectionsDueThisMonthKpiQueryVariables>(DashboardNbInspectionsDueThisMonthKpiDocument, baseOptions);
        }
export type DashboardNbInspectionsDueThisMonthKpiQueryHookResult = ReturnType<typeof useDashboardNbInspectionsDueThisMonthKpiQuery>;
export type DashboardNbInspectionsDueThisMonthKpiLazyQueryHookResult = ReturnType<typeof useDashboardNbInspectionsDueThisMonthKpiLazyQuery>;
export type DashboardNbInspectionsDueThisMonthKpiQueryResult = ApolloReactCommon.QueryResult<DashboardNbInspectionsDueThisMonthKpiQuery, DashboardNbInspectionsDueThisMonthKpiQueryVariables>;
export const DashboardNbInspectionsFailedLast30DaysKpiDocument = gql`
    query dashboardNbInspectionsFailedLast30DaysKpi($siteId: ID) {
  dashboard(siteId: $siteId) {
    nbInspectionsFailedLast30Days
    nbInspectionsFailedPrevious30Days
  }
}
    `;

/**
 * __useDashboardNbInspectionsFailedLast30DaysKpiQuery__
 *
 * To run a query within a React component, call `useDashboardNbInspectionsFailedLast30DaysKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardNbInspectionsFailedLast30DaysKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardNbInspectionsFailedLast30DaysKpiQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useDashboardNbInspectionsFailedLast30DaysKpiQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardNbInspectionsFailedLast30DaysKpiQuery, DashboardNbInspectionsFailedLast30DaysKpiQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardNbInspectionsFailedLast30DaysKpiQuery, DashboardNbInspectionsFailedLast30DaysKpiQueryVariables>(DashboardNbInspectionsFailedLast30DaysKpiDocument, baseOptions);
      }
export function useDashboardNbInspectionsFailedLast30DaysKpiLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardNbInspectionsFailedLast30DaysKpiQuery, DashboardNbInspectionsFailedLast30DaysKpiQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardNbInspectionsFailedLast30DaysKpiQuery, DashboardNbInspectionsFailedLast30DaysKpiQueryVariables>(DashboardNbInspectionsFailedLast30DaysKpiDocument, baseOptions);
        }
export type DashboardNbInspectionsFailedLast30DaysKpiQueryHookResult = ReturnType<typeof useDashboardNbInspectionsFailedLast30DaysKpiQuery>;
export type DashboardNbInspectionsFailedLast30DaysKpiLazyQueryHookResult = ReturnType<typeof useDashboardNbInspectionsFailedLast30DaysKpiLazyQuery>;
export type DashboardNbInspectionsFailedLast30DaysKpiQueryResult = ApolloReactCommon.QueryResult<DashboardNbInspectionsFailedLast30DaysKpiQuery, DashboardNbInspectionsFailedLast30DaysKpiQueryVariables>;
export const DashboardAvgRepairTimeLast30DaysKpiDocument = gql`
    query dashboardAvgRepairTimeLast30DaysKpi($siteId: ID) {
  dashboard(siteId: $siteId) {
    avgRepairMinutesLast30Days
    avgRepairMinutesPrevious30Days
  }
}
    `;

/**
 * __useDashboardAvgRepairTimeLast30DaysKpiQuery__
 *
 * To run a query within a React component, call `useDashboardAvgRepairTimeLast30DaysKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardAvgRepairTimeLast30DaysKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardAvgRepairTimeLast30DaysKpiQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useDashboardAvgRepairTimeLast30DaysKpiQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardAvgRepairTimeLast30DaysKpiQuery, DashboardAvgRepairTimeLast30DaysKpiQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardAvgRepairTimeLast30DaysKpiQuery, DashboardAvgRepairTimeLast30DaysKpiQueryVariables>(DashboardAvgRepairTimeLast30DaysKpiDocument, baseOptions);
      }
export function useDashboardAvgRepairTimeLast30DaysKpiLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardAvgRepairTimeLast30DaysKpiQuery, DashboardAvgRepairTimeLast30DaysKpiQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardAvgRepairTimeLast30DaysKpiQuery, DashboardAvgRepairTimeLast30DaysKpiQueryVariables>(DashboardAvgRepairTimeLast30DaysKpiDocument, baseOptions);
        }
export type DashboardAvgRepairTimeLast30DaysKpiQueryHookResult = ReturnType<typeof useDashboardAvgRepairTimeLast30DaysKpiQuery>;
export type DashboardAvgRepairTimeLast30DaysKpiLazyQueryHookResult = ReturnType<typeof useDashboardAvgRepairTimeLast30DaysKpiLazyQuery>;
export type DashboardAvgRepairTimeLast30DaysKpiQueryResult = ApolloReactCommon.QueryResult<DashboardAvgRepairTimeLast30DaysKpiQuery, DashboardAvgRepairTimeLast30DaysKpiQueryVariables>;
export const DashboardNbInspectionsDueByMonthDocument = gql`
    query dashboardNbInspectionsDueByMonth($siteId: ID) {
  dashboard(siteId: $siteId) {
    nbInspectionsDueByMonth {
      data {
        month
        year
        nbInspections
      }
    }
  }
}
    `;

/**
 * __useDashboardNbInspectionsDueByMonthQuery__
 *
 * To run a query within a React component, call `useDashboardNbInspectionsDueByMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardNbInspectionsDueByMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardNbInspectionsDueByMonthQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useDashboardNbInspectionsDueByMonthQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardNbInspectionsDueByMonthQuery, DashboardNbInspectionsDueByMonthQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardNbInspectionsDueByMonthQuery, DashboardNbInspectionsDueByMonthQueryVariables>(DashboardNbInspectionsDueByMonthDocument, baseOptions);
      }
export function useDashboardNbInspectionsDueByMonthLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardNbInspectionsDueByMonthQuery, DashboardNbInspectionsDueByMonthQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardNbInspectionsDueByMonthQuery, DashboardNbInspectionsDueByMonthQueryVariables>(DashboardNbInspectionsDueByMonthDocument, baseOptions);
        }
export type DashboardNbInspectionsDueByMonthQueryHookResult = ReturnType<typeof useDashboardNbInspectionsDueByMonthQuery>;
export type DashboardNbInspectionsDueByMonthLazyQueryHookResult = ReturnType<typeof useDashboardNbInspectionsDueByMonthLazyQuery>;
export type DashboardNbInspectionsDueByMonthQueryResult = ApolloReactCommon.QueryResult<DashboardNbInspectionsDueByMonthQuery, DashboardNbInspectionsDueByMonthQueryVariables>;
export const DashboardAccountInspectionsOverviewDocument = gql`
    query dashboardAccountInspectionsOverview {
  dashboard {
    accountInspectionsOverview {
      sites {
        id
        name
        passed
        failed
        due
        overdue
        noFreq
      }
    }
  }
}
    `;

/**
 * __useDashboardAccountInspectionsOverviewQuery__
 *
 * To run a query within a React component, call `useDashboardAccountInspectionsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardAccountInspectionsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardAccountInspectionsOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardAccountInspectionsOverviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardAccountInspectionsOverviewQuery, DashboardAccountInspectionsOverviewQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardAccountInspectionsOverviewQuery, DashboardAccountInspectionsOverviewQueryVariables>(DashboardAccountInspectionsOverviewDocument, baseOptions);
      }
export function useDashboardAccountInspectionsOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardAccountInspectionsOverviewQuery, DashboardAccountInspectionsOverviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardAccountInspectionsOverviewQuery, DashboardAccountInspectionsOverviewQueryVariables>(DashboardAccountInspectionsOverviewDocument, baseOptions);
        }
export type DashboardAccountInspectionsOverviewQueryHookResult = ReturnType<typeof useDashboardAccountInspectionsOverviewQuery>;
export type DashboardAccountInspectionsOverviewLazyQueryHookResult = ReturnType<typeof useDashboardAccountInspectionsOverviewLazyQuery>;
export type DashboardAccountInspectionsOverviewQueryResult = ApolloReactCommon.QueryResult<DashboardAccountInspectionsOverviewQuery, DashboardAccountInspectionsOverviewQueryVariables>;
export const DashboardSiteInspectionsOverviewDocument = gql`
    query dashboardSiteInspectionsOverview($siteId: ID) {
  dashboard(siteId: $siteId) {
    siteInspectionsOverview {
      doors {
        doorId
        locationOrDoorRef
        inspectionOverviewStatus
        doorStatusCode
      }
    }
  }
}
    `;

/**
 * __useDashboardSiteInspectionsOverviewQuery__
 *
 * To run a query within a React component, call `useDashboardSiteInspectionsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardSiteInspectionsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardSiteInspectionsOverviewQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useDashboardSiteInspectionsOverviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardSiteInspectionsOverviewQuery, DashboardSiteInspectionsOverviewQueryVariables>) {
        return ApolloReactHooks.useQuery<DashboardSiteInspectionsOverviewQuery, DashboardSiteInspectionsOverviewQueryVariables>(DashboardSiteInspectionsOverviewDocument, baseOptions);
      }
export function useDashboardSiteInspectionsOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardSiteInspectionsOverviewQuery, DashboardSiteInspectionsOverviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashboardSiteInspectionsOverviewQuery, DashboardSiteInspectionsOverviewQueryVariables>(DashboardSiteInspectionsOverviewDocument, baseOptions);
        }
export type DashboardSiteInspectionsOverviewQueryHookResult = ReturnType<typeof useDashboardSiteInspectionsOverviewQuery>;
export type DashboardSiteInspectionsOverviewLazyQueryHookResult = ReturnType<typeof useDashboardSiteInspectionsOverviewLazyQuery>;
export type DashboardSiteInspectionsOverviewQueryResult = ApolloReactCommon.QueryResult<DashboardSiteInspectionsOverviewQuery, DashboardSiteInspectionsOverviewQueryVariables>;
export const DoorsDocument = gql`
    query doors($skip: Int, $take: Int, $sortField: String, $sortDirection: String, $statuses: [String!], $textFilter: String, $active: Boolean, $stage: DoorFilterStages, $excludeDoorsWithSite: Boolean) {
  doors(skip: $skip, take: $take, sortField: $sortField, sortDirection: $sortDirection, statuses: $statuses, textFilter: $textFilter, active: $active, stage: $stage, excludeDoorsWithSite: $excludeDoorsWithSite) {
    items {
      id
      projectReference
      doorReference
      tagId
      model {
        id
        name
        partsListComplete
        fireCertificateComplete
        installationGuideComplete
        warrantyComplete
      }
      partsListComplete
      fireCertificateComplete
      installationGuideComplete
      warrantyComplete
      deletedAt
      status {
        name
        code
      }
      transferredToInstallerAt
      installer {
        id
      }
      site {
        id
      }
    }
    total
    hasMore
  }
}
    `;

/**
 * __useDoorsQuery__
 *
 * To run a query within a React component, call `useDoorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoorsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *      statuses: // value for 'statuses'
 *      textFilter: // value for 'textFilter'
 *      active: // value for 'active'
 *      stage: // value for 'stage'
 *      excludeDoorsWithSite: // value for 'excludeDoorsWithSite'
 *   },
 * });
 */
export function useDoorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DoorsQuery, DoorsQueryVariables>) {
        return ApolloReactHooks.useQuery<DoorsQuery, DoorsQueryVariables>(DoorsDocument, baseOptions);
      }
export function useDoorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DoorsQuery, DoorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DoorsQuery, DoorsQueryVariables>(DoorsDocument, baseOptions);
        }
export type DoorsQueryHookResult = ReturnType<typeof useDoorsQuery>;
export type DoorsLazyQueryHookResult = ReturnType<typeof useDoorsLazyQuery>;
export type DoorsQueryResult = ApolloReactCommon.QueryResult<DoorsQuery, DoorsQueryVariables>;
export const DoorDocument = gql`
    query door($id: ID!) {
  door(id: $id) {
    ...Door
  }
}
    ${DoorFragmentDoc}`;

/**
 * __useDoorQuery__
 *
 * To run a query within a React component, call `useDoorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDoorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DoorQuery, DoorQueryVariables>) {
        return ApolloReactHooks.useQuery<DoorQuery, DoorQueryVariables>(DoorDocument, baseOptions);
      }
export function useDoorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DoorQuery, DoorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DoorQuery, DoorQueryVariables>(DoorDocument, baseOptions);
        }
export type DoorQueryHookResult = ReturnType<typeof useDoorQuery>;
export type DoorLazyQueryHookResult = ReturnType<typeof useDoorLazyQuery>;
export type DoorQueryResult = ApolloReactCommon.QueryResult<DoorQuery, DoorQueryVariables>;
export const DoorByTagIdDocument = gql`
    query doorByTagId($tagId: String!) {
  doorByTagId(tagId: $tagId) {
    ...Door
  }
}
    ${DoorFragmentDoc}`;

/**
 * __useDoorByTagIdQuery__
 *
 * To run a query within a React component, call `useDoorByTagIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoorByTagIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoorByTagIdQuery({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useDoorByTagIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DoorByTagIdQuery, DoorByTagIdQueryVariables>) {
        return ApolloReactHooks.useQuery<DoorByTagIdQuery, DoorByTagIdQueryVariables>(DoorByTagIdDocument, baseOptions);
      }
export function useDoorByTagIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DoorByTagIdQuery, DoorByTagIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DoorByTagIdQuery, DoorByTagIdQueryVariables>(DoorByTagIdDocument, baseOptions);
        }
export type DoorByTagIdQueryHookResult = ReturnType<typeof useDoorByTagIdQuery>;
export type DoorByTagIdLazyQueryHookResult = ReturnType<typeof useDoorByTagIdLazyQuery>;
export type DoorByTagIdQueryResult = ApolloReactCommon.QueryResult<DoorByTagIdQuery, DoorByTagIdQueryVariables>;
export const DoorsByIdsDocument = gql`
    query doorsByIds($ids: [ID!]!) {
  doorsByIds(ids: $ids) {
    id
    tagId
    projectReference
    doorReference
    model {
      id
      name
    }
  }
}
    `;

/**
 * __useDoorsByIdsQuery__
 *
 * To run a query within a React component, call `useDoorsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoorsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoorsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDoorsByIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DoorsByIdsQuery, DoorsByIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<DoorsByIdsQuery, DoorsByIdsQueryVariables>(DoorsByIdsDocument, baseOptions);
      }
export function useDoorsByIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DoorsByIdsQuery, DoorsByIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DoorsByIdsQuery, DoorsByIdsQueryVariables>(DoorsByIdsDocument, baseOptions);
        }
export type DoorsByIdsQueryHookResult = ReturnType<typeof useDoorsByIdsQuery>;
export type DoorsByIdsLazyQueryHookResult = ReturnType<typeof useDoorsByIdsLazyQuery>;
export type DoorsByIdsQueryResult = ApolloReactCommon.QueryResult<DoorsByIdsQuery, DoorsByIdsQueryVariables>;
export const DoorLocationDocument = gql`
    query doorLocation($id: ID!) {
  door(id: $id) {
    id
    location
    locationType {
      id
    }
  }
}
    `;

/**
 * __useDoorLocationQuery__
 *
 * To run a query within a React component, call `useDoorLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoorLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoorLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDoorLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DoorLocationQuery, DoorLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<DoorLocationQuery, DoorLocationQueryVariables>(DoorLocationDocument, baseOptions);
      }
export function useDoorLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DoorLocationQuery, DoorLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DoorLocationQuery, DoorLocationQueryVariables>(DoorLocationDocument, baseOptions);
        }
export type DoorLocationQueryHookResult = ReturnType<typeof useDoorLocationQuery>;
export type DoorLocationLazyQueryHookResult = ReturnType<typeof useDoorLocationLazyQuery>;
export type DoorLocationQueryResult = ApolloReactCommon.QueryResult<DoorLocationQuery, DoorLocationQueryVariables>;
export const IsBarcodeValidDocument = gql`
    query isBarcodeValid($doorId: ID, $barcode: String!) {
  isBarcodeValid(doorId: $doorId, barcode: $barcode)
}
    `;

/**
 * __useIsBarcodeValidQuery__
 *
 * To run a query within a React component, call `useIsBarcodeValidQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsBarcodeValidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsBarcodeValidQuery({
 *   variables: {
 *      doorId: // value for 'doorId'
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useIsBarcodeValidQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsBarcodeValidQuery, IsBarcodeValidQueryVariables>) {
        return ApolloReactHooks.useQuery<IsBarcodeValidQuery, IsBarcodeValidQueryVariables>(IsBarcodeValidDocument, baseOptions);
      }
export function useIsBarcodeValidLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsBarcodeValidQuery, IsBarcodeValidQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsBarcodeValidQuery, IsBarcodeValidQueryVariables>(IsBarcodeValidDocument, baseOptions);
        }
export type IsBarcodeValidQueryHookResult = ReturnType<typeof useIsBarcodeValidQuery>;
export type IsBarcodeValidLazyQueryHookResult = ReturnType<typeof useIsBarcodeValidLazyQuery>;
export type IsBarcodeValidQueryResult = ApolloReactCommon.QueryResult<IsBarcodeValidQuery, IsBarcodeValidQueryVariables>;
export const AssignDoorsToSiteDocument = gql`
    mutation assignDoorsToSite($doorsInput: [AssignDoorToSiteInput!]!, $siteId: ID!) {
  assignDoorsToSite(doorsInput: $doorsInput, siteId: $siteId)
}
    `;
export type AssignDoorsToSiteMutationFn = ApolloReactCommon.MutationFunction<AssignDoorsToSiteMutation, AssignDoorsToSiteMutationVariables>;

/**
 * __useAssignDoorsToSiteMutation__
 *
 * To run a mutation, you first call `useAssignDoorsToSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDoorsToSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDoorsToSiteMutation, { data, loading, error }] = useAssignDoorsToSiteMutation({
 *   variables: {
 *      doorsInput: // value for 'doorsInput'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useAssignDoorsToSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignDoorsToSiteMutation, AssignDoorsToSiteMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignDoorsToSiteMutation, AssignDoorsToSiteMutationVariables>(AssignDoorsToSiteDocument, baseOptions);
      }
export type AssignDoorsToSiteMutationHookResult = ReturnType<typeof useAssignDoorsToSiteMutation>;
export type AssignDoorsToSiteMutationResult = ApolloReactCommon.MutationResult<AssignDoorsToSiteMutation>;
export type AssignDoorsToSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignDoorsToSiteMutation, AssignDoorsToSiteMutationVariables>;
export const DisableDoorsDocument = gql`
    mutation disableDoors($idListInput: IdListInput!) {
  disableDoors(idListInput: $idListInput)
}
    `;
export type DisableDoorsMutationFn = ApolloReactCommon.MutationFunction<DisableDoorsMutation, DisableDoorsMutationVariables>;

/**
 * __useDisableDoorsMutation__
 *
 * To run a mutation, you first call `useDisableDoorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableDoorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableDoorsMutation, { data, loading, error }] = useDisableDoorsMutation({
 *   variables: {
 *      idListInput: // value for 'idListInput'
 *   },
 * });
 */
export function useDisableDoorsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisableDoorsMutation, DisableDoorsMutationVariables>) {
        return ApolloReactHooks.useMutation<DisableDoorsMutation, DisableDoorsMutationVariables>(DisableDoorsDocument, baseOptions);
      }
export type DisableDoorsMutationHookResult = ReturnType<typeof useDisableDoorsMutation>;
export type DisableDoorsMutationResult = ApolloReactCommon.MutationResult<DisableDoorsMutation>;
export type DisableDoorsMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableDoorsMutation, DisableDoorsMutationVariables>;
export const UploadDoorsFileDocument = gql`
    mutation uploadDoorsFile($file: Upload!, $siteId: ID) {
  uploadDoorsFile(file: $file, siteId: $siteId)
}
    `;
export type UploadDoorsFileMutationFn = ApolloReactCommon.MutationFunction<UploadDoorsFileMutation, UploadDoorsFileMutationVariables>;

/**
 * __useUploadDoorsFileMutation__
 *
 * To run a mutation, you first call `useUploadDoorsFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDoorsFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDoorsFileMutation, { data, loading, error }] = useUploadDoorsFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useUploadDoorsFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadDoorsFileMutation, UploadDoorsFileMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadDoorsFileMutation, UploadDoorsFileMutationVariables>(UploadDoorsFileDocument, baseOptions);
      }
export type UploadDoorsFileMutationHookResult = ReturnType<typeof useUploadDoorsFileMutation>;
export type UploadDoorsFileMutationResult = ApolloReactCommon.MutationResult<UploadDoorsFileMutation>;
export type UploadDoorsFileMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadDoorsFileMutation, UploadDoorsFileMutationVariables>;
export const AddDoorDocument = gql`
    mutation addDoor($door: DoorInput!) {
  addDoor(door: $door) {
    id
  }
}
    `;
export type AddDoorMutationFn = ApolloReactCommon.MutationFunction<AddDoorMutation, AddDoorMutationVariables>;

/**
 * __useAddDoorMutation__
 *
 * To run a mutation, you first call `useAddDoorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDoorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDoorMutation, { data, loading, error }] = useAddDoorMutation({
 *   variables: {
 *      door: // value for 'door'
 *   },
 * });
 */
export function useAddDoorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDoorMutation, AddDoorMutationVariables>) {
        return ApolloReactHooks.useMutation<AddDoorMutation, AddDoorMutationVariables>(AddDoorDocument, baseOptions);
      }
export type AddDoorMutationHookResult = ReturnType<typeof useAddDoorMutation>;
export type AddDoorMutationResult = ApolloReactCommon.MutationResult<AddDoorMutation>;
export type AddDoorMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDoorMutation, AddDoorMutationVariables>;
export const EditDoorDocument = gql`
    mutation editDoor($door: DoorInput!) {
  addDoor(door: $door) {
    ...Door
  }
}
    ${DoorFragmentDoc}`;
export type EditDoorMutationFn = ApolloReactCommon.MutationFunction<EditDoorMutation, EditDoorMutationVariables>;

/**
 * __useEditDoorMutation__
 *
 * To run a mutation, you first call `useEditDoorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDoorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDoorMutation, { data, loading, error }] = useEditDoorMutation({
 *   variables: {
 *      door: // value for 'door'
 *   },
 * });
 */
export function useEditDoorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditDoorMutation, EditDoorMutationVariables>) {
        return ApolloReactHooks.useMutation<EditDoorMutation, EditDoorMutationVariables>(EditDoorDocument, baseOptions);
      }
export type EditDoorMutationHookResult = ReturnType<typeof useEditDoorMutation>;
export type EditDoorMutationResult = ApolloReactCommon.MutationResult<EditDoorMutation>;
export type EditDoorMutationOptions = ApolloReactCommon.BaseMutationOptions<EditDoorMutation, EditDoorMutationVariables>;
export const TransferDoorsDocument = gql`
    mutation transferDoors($ids: [Float!]!, $destinationCompanyId: ID, $destinationCompanyName: String, $destinationUserEmail: String!, $transferType: TransferTypes!, $orderRef: String!, $tagCompanyId: ID, $tagUserEmail: String) {
  transferDoors(ids: $ids, destinationCompanyId: $destinationCompanyId, destinationCompanyName: $destinationCompanyName, destinationUserEmail: $destinationUserEmail, transferType: $transferType, orderRef: $orderRef, tagCompanyId: $tagCompanyId, tagUserEmail: $tagUserEmail)
}
    `;
export type TransferDoorsMutationFn = ApolloReactCommon.MutationFunction<TransferDoorsMutation, TransferDoorsMutationVariables>;

/**
 * __useTransferDoorsMutation__
 *
 * To run a mutation, you first call `useTransferDoorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferDoorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferDoorsMutation, { data, loading, error }] = useTransferDoorsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      destinationCompanyId: // value for 'destinationCompanyId'
 *      destinationCompanyName: // value for 'destinationCompanyName'
 *      destinationUserEmail: // value for 'destinationUserEmail'
 *      transferType: // value for 'transferType'
 *      orderRef: // value for 'orderRef'
 *      tagCompanyId: // value for 'tagCompanyId'
 *      tagUserEmail: // value for 'tagUserEmail'
 *   },
 * });
 */
export function useTransferDoorsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TransferDoorsMutation, TransferDoorsMutationVariables>) {
        return ApolloReactHooks.useMutation<TransferDoorsMutation, TransferDoorsMutationVariables>(TransferDoorsDocument, baseOptions);
      }
export type TransferDoorsMutationHookResult = ReturnType<typeof useTransferDoorsMutation>;
export type TransferDoorsMutationResult = ApolloReactCommon.MutationResult<TransferDoorsMutation>;
export type TransferDoorsMutationOptions = ApolloReactCommon.BaseMutationOptions<TransferDoorsMutation, TransferDoorsMutationVariables>;
export const AssignDoorsToContractorDocument = gql`
    mutation assignDoorsToContractor($ids: [Float!]!, $destinationCompanyId: ID!, $destinationUserEmail: String!, $workTypeCode: String!) {
  assignDoorsToContractor(ids: $ids, destinationCompanyId: $destinationCompanyId, destinationUserEmail: $destinationUserEmail, workTypeCode: $workTypeCode)
}
    `;
export type AssignDoorsToContractorMutationFn = ApolloReactCommon.MutationFunction<AssignDoorsToContractorMutation, AssignDoorsToContractorMutationVariables>;

/**
 * __useAssignDoorsToContractorMutation__
 *
 * To run a mutation, you first call `useAssignDoorsToContractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDoorsToContractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDoorsToContractorMutation, { data, loading, error }] = useAssignDoorsToContractorMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      destinationCompanyId: // value for 'destinationCompanyId'
 *      destinationUserEmail: // value for 'destinationUserEmail'
 *      workTypeCode: // value for 'workTypeCode'
 *   },
 * });
 */
export function useAssignDoorsToContractorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignDoorsToContractorMutation, AssignDoorsToContractorMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignDoorsToContractorMutation, AssignDoorsToContractorMutationVariables>(AssignDoorsToContractorDocument, baseOptions);
      }
export type AssignDoorsToContractorMutationHookResult = ReturnType<typeof useAssignDoorsToContractorMutation>;
export type AssignDoorsToContractorMutationResult = ApolloReactCommon.MutationResult<AssignDoorsToContractorMutation>;
export type AssignDoorsToContractorMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignDoorsToContractorMutation, AssignDoorsToContractorMutationVariables>;
export const RemoveContractorFromDoorsDocument = gql`
    mutation removeContractorFromDoors($ids: [Float!]!, $contractorCompanyId: ID!) {
  removeContractorFromDoors(ids: $ids, contractorCompanyId: $contractorCompanyId)
}
    `;
export type RemoveContractorFromDoorsMutationFn = ApolloReactCommon.MutationFunction<RemoveContractorFromDoorsMutation, RemoveContractorFromDoorsMutationVariables>;

/**
 * __useRemoveContractorFromDoorsMutation__
 *
 * To run a mutation, you first call `useRemoveContractorFromDoorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContractorFromDoorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContractorFromDoorsMutation, { data, loading, error }] = useRemoveContractorFromDoorsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      contractorCompanyId: // value for 'contractorCompanyId'
 *   },
 * });
 */
export function useRemoveContractorFromDoorsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveContractorFromDoorsMutation, RemoveContractorFromDoorsMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveContractorFromDoorsMutation, RemoveContractorFromDoorsMutationVariables>(RemoveContractorFromDoorsDocument, baseOptions);
      }
export type RemoveContractorFromDoorsMutationHookResult = ReturnType<typeof useRemoveContractorFromDoorsMutation>;
export type RemoveContractorFromDoorsMutationResult = ApolloReactCommon.MutationResult<RemoveContractorFromDoorsMutation>;
export type RemoveContractorFromDoorsMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveContractorFromDoorsMutation, RemoveContractorFromDoorsMutationVariables>;
export const RetireDoorsDocument = gql`
    mutation retireDoors($retireDoorInput: RetireDoorInput!) {
  retireDoors(retireDoorInput: $retireDoorInput)
}
    `;
export type RetireDoorsMutationFn = ApolloReactCommon.MutationFunction<RetireDoorsMutation, RetireDoorsMutationVariables>;

/**
 * __useRetireDoorsMutation__
 *
 * To run a mutation, you first call `useRetireDoorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetireDoorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retireDoorsMutation, { data, loading, error }] = useRetireDoorsMutation({
 *   variables: {
 *      retireDoorInput: // value for 'retireDoorInput'
 *   },
 * });
 */
export function useRetireDoorsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RetireDoorsMutation, RetireDoorsMutationVariables>) {
        return ApolloReactHooks.useMutation<RetireDoorsMutation, RetireDoorsMutationVariables>(RetireDoorsDocument, baseOptions);
      }
export type RetireDoorsMutationHookResult = ReturnType<typeof useRetireDoorsMutation>;
export type RetireDoorsMutationResult = ApolloReactCommon.MutationResult<RetireDoorsMutation>;
export type RetireDoorsMutationOptions = ApolloReactCommon.BaseMutationOptions<RetireDoorsMutation, RetireDoorsMutationVariables>;
export const MarkDoorForRepairDocument = gql`
    mutation markDoorForRepair($markDoorForRepairInput: MarkDoorForRepairInput!) {
  markDoorForRepair(markDoorForRepairInput: $markDoorForRepairInput)
}
    `;
export type MarkDoorForRepairMutationFn = ApolloReactCommon.MutationFunction<MarkDoorForRepairMutation, MarkDoorForRepairMutationVariables>;

/**
 * __useMarkDoorForRepairMutation__
 *
 * To run a mutation, you first call `useMarkDoorForRepairMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkDoorForRepairMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markDoorForRepairMutation, { data, loading, error }] = useMarkDoorForRepairMutation({
 *   variables: {
 *      markDoorForRepairInput: // value for 'markDoorForRepairInput'
 *   },
 * });
 */
export function useMarkDoorForRepairMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkDoorForRepairMutation, MarkDoorForRepairMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkDoorForRepairMutation, MarkDoorForRepairMutationVariables>(MarkDoorForRepairDocument, baseOptions);
      }
export type MarkDoorForRepairMutationHookResult = ReturnType<typeof useMarkDoorForRepairMutation>;
export type MarkDoorForRepairMutationResult = ApolloReactCommon.MutationResult<MarkDoorForRepairMutation>;
export type MarkDoorForRepairMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkDoorForRepairMutation, MarkDoorForRepairMutationVariables>;
export const DoorModelDocument = gql`
    query doorModel($id: ID!) {
  doorModel(id: $id) {
    ...DoorModel
    documents {
      id
      originalFilename
      description
      documentType {
        id
        code
        name
      }
    }
  }
}
    ${DoorModelFragmentDoc}`;

/**
 * __useDoorModelQuery__
 *
 * To run a query within a React component, call `useDoorModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoorModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoorModelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDoorModelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DoorModelQuery, DoorModelQueryVariables>) {
        return ApolloReactHooks.useQuery<DoorModelQuery, DoorModelQueryVariables>(DoorModelDocument, baseOptions);
      }
export function useDoorModelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DoorModelQuery, DoorModelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DoorModelQuery, DoorModelQueryVariables>(DoorModelDocument, baseOptions);
        }
export type DoorModelQueryHookResult = ReturnType<typeof useDoorModelQuery>;
export type DoorModelLazyQueryHookResult = ReturnType<typeof useDoorModelLazyQuery>;
export type DoorModelQueryResult = ApolloReactCommon.QueryResult<DoorModelQuery, DoorModelQueryVariables>;
export const DoorModelsDocument = gql`
    query doorModels($skip: Int, $take: Int, $sortField: String, $sortDirection: String) {
  doorModels(skip: $skip, take: $take, sortField: $sortField, sortDirection: $sortDirection) {
    items {
      id
      name
      fdRating {
        id
        value
      }
      coreSupplier
      partsListComplete
      fireCertificateComplete
      installationGuideComplete
      warrantyComplete
    }
    total
    hasMore
  }
}
    `;

/**
 * __useDoorModelsQuery__
 *
 * To run a query within a React component, call `useDoorModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoorModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoorModelsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useDoorModelsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DoorModelsQuery, DoorModelsQueryVariables>) {
        return ApolloReactHooks.useQuery<DoorModelsQuery, DoorModelsQueryVariables>(DoorModelsDocument, baseOptions);
      }
export function useDoorModelsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DoorModelsQuery, DoorModelsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DoorModelsQuery, DoorModelsQueryVariables>(DoorModelsDocument, baseOptions);
        }
export type DoorModelsQueryHookResult = ReturnType<typeof useDoorModelsQuery>;
export type DoorModelsLazyQueryHookResult = ReturnType<typeof useDoorModelsLazyQuery>;
export type DoorModelsQueryResult = ApolloReactCommon.QueryResult<DoorModelsQuery, DoorModelsQueryVariables>;
export const SaveDoorModelDocument = gql`
    mutation saveDoorModel($doorModelInput: DoorModelInput!) {
  saveDoorModel(doorModelInput: $doorModelInput)
}
    `;
export type SaveDoorModelMutationFn = ApolloReactCommon.MutationFunction<SaveDoorModelMutation, SaveDoorModelMutationVariables>;

/**
 * __useSaveDoorModelMutation__
 *
 * To run a mutation, you first call `useSaveDoorModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDoorModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDoorModelMutation, { data, loading, error }] = useSaveDoorModelMutation({
 *   variables: {
 *      doorModelInput: // value for 'doorModelInput'
 *   },
 * });
 */
export function useSaveDoorModelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveDoorModelMutation, SaveDoorModelMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveDoorModelMutation, SaveDoorModelMutationVariables>(SaveDoorModelDocument, baseOptions);
      }
export type SaveDoorModelMutationHookResult = ReturnType<typeof useSaveDoorModelMutation>;
export type SaveDoorModelMutationResult = ApolloReactCommon.MutationResult<SaveDoorModelMutation>;
export type SaveDoorModelMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveDoorModelMutation, SaveDoorModelMutationVariables>;
export const DeleteDoorModelsDocument = gql`
    mutation deleteDoorModels($ids: [ID!]!) {
  deleteDoorModels(ids: $ids)
}
    `;
export type DeleteDoorModelsMutationFn = ApolloReactCommon.MutationFunction<DeleteDoorModelsMutation, DeleteDoorModelsMutationVariables>;

/**
 * __useDeleteDoorModelsMutation__
 *
 * To run a mutation, you first call `useDeleteDoorModelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDoorModelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDoorModelsMutation, { data, loading, error }] = useDeleteDoorModelsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteDoorModelsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDoorModelsMutation, DeleteDoorModelsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDoorModelsMutation, DeleteDoorModelsMutationVariables>(DeleteDoorModelsDocument, baseOptions);
      }
export type DeleteDoorModelsMutationHookResult = ReturnType<typeof useDeleteDoorModelsMutation>;
export type DeleteDoorModelsMutationResult = ApolloReactCommon.MutationResult<DeleteDoorModelsMutation>;
export type DeleteDoorModelsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDoorModelsMutation, DeleteDoorModelsMutationVariables>;
export const SaveDoorModelDocumentsDocument = gql`
    mutation saveDoorModelDocuments($doorModelDocumentsInput: DoorModelDocumentsInput!) {
  saveDoorModelDocuments(doorModelDocumentsInput: $doorModelDocumentsInput) {
    ...DoorModel
    documents {
      id
      originalFilename
      description
      documentType {
        id
        code
        name
      }
    }
  }
}
    ${DoorModelFragmentDoc}`;
export type SaveDoorModelDocumentsMutationFn = ApolloReactCommon.MutationFunction<SaveDoorModelDocumentsMutation, SaveDoorModelDocumentsMutationVariables>;

/**
 * __useSaveDoorModelDocumentsMutation__
 *
 * To run a mutation, you first call `useSaveDoorModelDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDoorModelDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDoorModelDocumentsMutation, { data, loading, error }] = useSaveDoorModelDocumentsMutation({
 *   variables: {
 *      doorModelDocumentsInput: // value for 'doorModelDocumentsInput'
 *   },
 * });
 */
export function useSaveDoorModelDocumentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveDoorModelDocumentsMutation, SaveDoorModelDocumentsMutationVariables>) {
        return ApolloReactHooks.useMutation<SaveDoorModelDocumentsMutation, SaveDoorModelDocumentsMutationVariables>(SaveDoorModelDocumentsDocument, baseOptions);
      }
export type SaveDoorModelDocumentsMutationHookResult = ReturnType<typeof useSaveDoorModelDocumentsMutation>;
export type SaveDoorModelDocumentsMutationResult = ApolloReactCommon.MutationResult<SaveDoorModelDocumentsMutation>;
export type SaveDoorModelDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveDoorModelDocumentsMutation, SaveDoorModelDocumentsMutationVariables>;
export const DoorModelsByNameDocument = gql`
    query doorModelsByName($name: String!) {
  doorModelsByName(name: $name) {
    id
    name
  }
}
    `;

/**
 * __useDoorModelsByNameQuery__
 *
 * To run a query within a React component, call `useDoorModelsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoorModelsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoorModelsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDoorModelsByNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DoorModelsByNameQuery, DoorModelsByNameQueryVariables>) {
        return ApolloReactHooks.useQuery<DoorModelsByNameQuery, DoorModelsByNameQueryVariables>(DoorModelsByNameDocument, baseOptions);
      }
export function useDoorModelsByNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DoorModelsByNameQuery, DoorModelsByNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DoorModelsByNameQuery, DoorModelsByNameQueryVariables>(DoorModelsByNameDocument, baseOptions);
        }
export type DoorModelsByNameQueryHookResult = ReturnType<typeof useDoorModelsByNameQuery>;
export type DoorModelsByNameLazyQueryHookResult = ReturnType<typeof useDoorModelsByNameLazyQuery>;
export type DoorModelsByNameQueryResult = ApolloReactCommon.QueryResult<DoorModelsByNameQuery, DoorModelsByNameQueryVariables>;
export const EditCompanyDocument = gql`
    mutation editCompany($companyInput: NewCompanyInput!) {
  editCompany(companyInput: $companyInput) {
    id
  }
}
    `;
export type EditCompanyMutationFn = ApolloReactCommon.MutationFunction<EditCompanyMutation, EditCompanyMutationVariables>;

/**
 * __useEditCompanyMutation__
 *
 * To run a mutation, you first call `useEditCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyMutation, { data, loading, error }] = useEditCompanyMutation({
 *   variables: {
 *      companyInput: // value for 'companyInput'
 *   },
 * });
 */
export function useEditCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditCompanyMutation, EditCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<EditCompanyMutation, EditCompanyMutationVariables>(EditCompanyDocument, baseOptions);
      }
export type EditCompanyMutationHookResult = ReturnType<typeof useEditCompanyMutation>;
export type EditCompanyMutationResult = ApolloReactCommon.MutationResult<EditCompanyMutation>;
export type EditCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<EditCompanyMutation, EditCompanyMutationVariables>;
export const EditSiteDocument = gql`
    mutation editSite($siteInput: SiteInput!) {
  editSite(siteInput: $siteInput) {
    id
  }
}
    `;
export type EditSiteMutationFn = ApolloReactCommon.MutationFunction<EditSiteMutation, EditSiteMutationVariables>;

/**
 * __useEditSiteMutation__
 *
 * To run a mutation, you first call `useEditSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSiteMutation, { data, loading, error }] = useEditSiteMutation({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useEditSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSiteMutation, EditSiteMutationVariables>) {
        return ApolloReactHooks.useMutation<EditSiteMutation, EditSiteMutationVariables>(EditSiteDocument, baseOptions);
      }
export type EditSiteMutationHookResult = ReturnType<typeof useEditSiteMutation>;
export type EditSiteMutationResult = ApolloReactCommon.MutationResult<EditSiteMutation>;
export type EditSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSiteMutation, EditSiteMutationVariables>;
export const ReportInstallationDocument = gql`
    mutation reportInstallation($reportInstallationDetails: ReportDoorInstallationInput!) {
  reportInstallation(reportInstallationDetails: $reportInstallationDetails)
}
    `;
export type ReportInstallationMutationFn = ApolloReactCommon.MutationFunction<ReportInstallationMutation, ReportInstallationMutationVariables>;

/**
 * __useReportInstallationMutation__
 *
 * To run a mutation, you first call `useReportInstallationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportInstallationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportInstallationMutation, { data, loading, error }] = useReportInstallationMutation({
 *   variables: {
 *      reportInstallationDetails: // value for 'reportInstallationDetails'
 *   },
 * });
 */
export function useReportInstallationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportInstallationMutation, ReportInstallationMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportInstallationMutation, ReportInstallationMutationVariables>(ReportInstallationDocument, baseOptions);
      }
export type ReportInstallationMutationHookResult = ReturnType<typeof useReportInstallationMutation>;
export type ReportInstallationMutationResult = ApolloReactCommon.MutationResult<ReportInstallationMutation>;
export type ReportInstallationMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportInstallationMutation, ReportInstallationMutationVariables>;
export const InviteCompanyDocument = gql`
    mutation inviteCompany($companyInput: NewCompanyInput!) {
  inviteCompany(companyInput: $companyInput) {
    id
  }
}
    `;
export type InviteCompanyMutationFn = ApolloReactCommon.MutationFunction<InviteCompanyMutation, InviteCompanyMutationVariables>;

/**
 * __useInviteCompanyMutation__
 *
 * To run a mutation, you first call `useInviteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteCompanyMutation, { data, loading, error }] = useInviteCompanyMutation({
 *   variables: {
 *      companyInput: // value for 'companyInput'
 *   },
 * });
 */
export function useInviteCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteCompanyMutation, InviteCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteCompanyMutation, InviteCompanyMutationVariables>(InviteCompanyDocument, baseOptions);
      }
export type InviteCompanyMutationHookResult = ReturnType<typeof useInviteCompanyMutation>;
export type InviteCompanyMutationResult = ApolloReactCommon.MutationResult<InviteCompanyMutation>;
export type InviteCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteCompanyMutation, InviteCompanyMutationVariables>;
export const LocationTypesDocument = gql`
    query locationTypes {
  locationTypes {
    id
    code
    name
  }
}
    `;

/**
 * __useLocationTypesQuery__
 *
 * To run a query within a React component, call `useLocationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationTypesQuery, LocationTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationTypesQuery, LocationTypesQueryVariables>(LocationTypesDocument, baseOptions);
      }
export function useLocationTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationTypesQuery, LocationTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationTypesQuery, LocationTypesQueryVariables>(LocationTypesDocument, baseOptions);
        }
export type LocationTypesQueryHookResult = ReturnType<typeof useLocationTypesQuery>;
export type LocationTypesLazyQueryHookResult = ReturnType<typeof useLocationTypesLazyQuery>;
export type LocationTypesQueryResult = ApolloReactCommon.QueryResult<LocationTypesQuery, LocationTypesQueryVariables>;
export const CompanyTypesDocument = gql`
    query companyTypes {
  companyTypes {
    ...CompanyType
  }
}
    ${CompanyTypeFragmentDoc}`;

/**
 * __useCompanyTypesQuery__
 *
 * To run a query within a React component, call `useCompanyTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyTypesQuery, CompanyTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyTypesQuery, CompanyTypesQueryVariables>(CompanyTypesDocument, baseOptions);
      }
export function useCompanyTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyTypesQuery, CompanyTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyTypesQuery, CompanyTypesQueryVariables>(CompanyTypesDocument, baseOptions);
        }
export type CompanyTypesQueryHookResult = ReturnType<typeof useCompanyTypesQuery>;
export type CompanyTypesLazyQueryHookResult = ReturnType<typeof useCompanyTypesLazyQuery>;
export type CompanyTypesQueryResult = ApolloReactCommon.QueryResult<CompanyTypesQuery, CompanyTypesQueryVariables>;
export const DocumentTypesDocument = gql`
    query documentTypes {
  documentTypes {
    id
    code
    name
  }
}
    `;

/**
 * __useDocumentTypesQuery__
 *
 * To run a query within a React component, call `useDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentTypesQuery, DocumentTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentTypesQuery, DocumentTypesQueryVariables>(DocumentTypesDocument, baseOptions);
      }
export function useDocumentTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentTypesQuery, DocumentTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentTypesQuery, DocumentTypesQueryVariables>(DocumentTypesDocument, baseOptions);
        }
export type DocumentTypesQueryHookResult = ReturnType<typeof useDocumentTypesQuery>;
export type DocumentTypesLazyQueryHookResult = ReturnType<typeof useDocumentTypesLazyQuery>;
export type DocumentTypesQueryResult = ApolloReactCommon.QueryResult<DocumentTypesQuery, DocumentTypesQueryVariables>;
export const FdRatingsDocument = gql`
    query fdRatings {
  fdRatings {
    value
    id
  }
}
    `;

/**
 * __useFdRatingsQuery__
 *
 * To run a query within a React component, call `useFdRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdRatingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFdRatingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FdRatingsQuery, FdRatingsQueryVariables>) {
        return ApolloReactHooks.useQuery<FdRatingsQuery, FdRatingsQueryVariables>(FdRatingsDocument, baseOptions);
      }
export function useFdRatingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FdRatingsQuery, FdRatingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FdRatingsQuery, FdRatingsQueryVariables>(FdRatingsDocument, baseOptions);
        }
export type FdRatingsQueryHookResult = ReturnType<typeof useFdRatingsQuery>;
export type FdRatingsLazyQueryHookResult = ReturnType<typeof useFdRatingsLazyQuery>;
export type FdRatingsQueryResult = ApolloReactCommon.QueryResult<FdRatingsQuery, FdRatingsQueryVariables>;
export const RemoveFromMySitesDocument = gql`
    mutation removeFromMySites($idListInput: IdListInput!) {
  removeFromMySites(idListInput: $idListInput)
}
    `;
export type RemoveFromMySitesMutationFn = ApolloReactCommon.MutationFunction<RemoveFromMySitesMutation, RemoveFromMySitesMutationVariables>;

/**
 * __useRemoveFromMySitesMutation__
 *
 * To run a mutation, you first call `useRemoveFromMySitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromMySitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromMySitesMutation, { data, loading, error }] = useRemoveFromMySitesMutation({
 *   variables: {
 *      idListInput: // value for 'idListInput'
 *   },
 * });
 */
export function useRemoveFromMySitesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFromMySitesMutation, RemoveFromMySitesMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveFromMySitesMutation, RemoveFromMySitesMutationVariables>(RemoveFromMySitesDocument, baseOptions);
      }
export type RemoveFromMySitesMutationHookResult = ReturnType<typeof useRemoveFromMySitesMutation>;
export type RemoveFromMySitesMutationResult = ApolloReactCommon.MutationResult<RemoveFromMySitesMutation>;
export type RemoveFromMySitesMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFromMySitesMutation, RemoveFromMySitesMutationVariables>;
export const AddToMySitesDocument = gql`
    mutation addToMySites($idListInput: IdListInput!) {
  addToMySites(idListInput: $idListInput)
}
    `;
export type AddToMySitesMutationFn = ApolloReactCommon.MutationFunction<AddToMySitesMutation, AddToMySitesMutationVariables>;

/**
 * __useAddToMySitesMutation__
 *
 * To run a mutation, you first call `useAddToMySitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToMySitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToMySitesMutation, { data, loading, error }] = useAddToMySitesMutation({
 *   variables: {
 *      idListInput: // value for 'idListInput'
 *   },
 * });
 */
export function useAddToMySitesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddToMySitesMutation, AddToMySitesMutationVariables>) {
        return ApolloReactHooks.useMutation<AddToMySitesMutation, AddToMySitesMutationVariables>(AddToMySitesDocument, baseOptions);
      }
export type AddToMySitesMutationHookResult = ReturnType<typeof useAddToMySitesMutation>;
export type AddToMySitesMutationResult = ApolloReactCommon.MutationResult<AddToMySitesMutation>;
export type AddToMySitesMutationOptions = ApolloReactCommon.BaseMutationOptions<AddToMySitesMutation, AddToMySitesMutationVariables>;
export const MySitesDocument = gql`
    query mySites($skip: Int, $take: Int, $sortField: String, $sortDirection: String, $searchTerm: String) {
  mySites(skip: $skip, take: $take, sortField: $sortField, sortDirection: $sortDirection, searchTerm: $searchTerm) {
    items {
      id
      name
      reference
      ownedBy {
        id
        name
      }
      areas
      addressOne
      addressTwo
      city
      postcode
      installedDoorCount
    }
    total
    hasMore
  }
}
    `;

/**
 * __useMySitesQuery__
 *
 * To run a query within a React component, call `useMySitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySitesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useMySitesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MySitesQuery, MySitesQueryVariables>) {
        return ApolloReactHooks.useQuery<MySitesQuery, MySitesQueryVariables>(MySitesDocument, baseOptions);
      }
export function useMySitesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MySitesQuery, MySitesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MySitesQuery, MySitesQueryVariables>(MySitesDocument, baseOptions);
        }
export type MySitesQueryHookResult = ReturnType<typeof useMySitesQuery>;
export type MySitesLazyQueryHookResult = ReturnType<typeof useMySitesLazyQuery>;
export type MySitesQueryResult = ApolloReactCommon.QueryResult<MySitesQuery, MySitesQueryVariables>;
export const MySitesHeaderDocument = gql`
    query mySitesHeader($skip: Int, $take: Int, $sortField: String, $sortDirection: String, $searchTerm: String) {
  mySites(skip: $skip, take: $take, sortField: $sortField, sortDirection: $sortDirection, searchTerm: $searchTerm) {
    items {
      id
      name
      ownedBy {
        id
      }
    }
    total
    hasMore
  }
}
    `;

/**
 * __useMySitesHeaderQuery__
 *
 * To run a query within a React component, call `useMySitesHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySitesHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySitesHeaderQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useMySitesHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MySitesHeaderQuery, MySitesHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<MySitesHeaderQuery, MySitesHeaderQueryVariables>(MySitesHeaderDocument, baseOptions);
      }
export function useMySitesHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MySitesHeaderQuery, MySitesHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MySitesHeaderQuery, MySitesHeaderQueryVariables>(MySitesHeaderDocument, baseOptions);
        }
export type MySitesHeaderQueryHookResult = ReturnType<typeof useMySitesHeaderQuery>;
export type MySitesHeaderLazyQueryHookResult = ReturnType<typeof useMySitesHeaderLazyQuery>;
export type MySitesHeaderQueryResult = ApolloReactCommon.QueryResult<MySitesHeaderQuery, MySitesHeaderQueryVariables>;
export const OwnerInspectionActivityDocument = gql`
    query ownerInspectionActivity($reportPeriodFrom: DateTime!, $reportPeriodTo: DateTime!, $siteId: ID) {
  ownerInspectionActivity(siteId: $siteId, reportPeriodFrom: $reportPeriodFrom, reportPeriodTo: $reportPeriodTo) {
    path
  }
}
    `;

/**
 * __useOwnerInspectionActivityQuery__
 *
 * To run a query within a React component, call `useOwnerInspectionActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerInspectionActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerInspectionActivityQuery({
 *   variables: {
 *      reportPeriodFrom: // value for 'reportPeriodFrom'
 *      reportPeriodTo: // value for 'reportPeriodTo'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useOwnerInspectionActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OwnerInspectionActivityQuery, OwnerInspectionActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<OwnerInspectionActivityQuery, OwnerInspectionActivityQueryVariables>(OwnerInspectionActivityDocument, baseOptions);
      }
export function useOwnerInspectionActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OwnerInspectionActivityQuery, OwnerInspectionActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OwnerInspectionActivityQuery, OwnerInspectionActivityQueryVariables>(OwnerInspectionActivityDocument, baseOptions);
        }
export type OwnerInspectionActivityQueryHookResult = ReturnType<typeof useOwnerInspectionActivityQuery>;
export type OwnerInspectionActivityLazyQueryHookResult = ReturnType<typeof useOwnerInspectionActivityLazyQuery>;
export type OwnerInspectionActivityQueryResult = ApolloReactCommon.QueryResult<OwnerInspectionActivityQuery, OwnerInspectionActivityQueryVariables>;
export const OwnerDoorStateDocument = gql`
    query ownerDoorState($siteId: ID) {
  ownerDoorState(siteId: $siteId) {
    path
  }
}
    `;

/**
 * __useOwnerDoorStateQuery__
 *
 * To run a query within a React component, call `useOwnerDoorStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerDoorStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerDoorStateQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useOwnerDoorStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OwnerDoorStateQuery, OwnerDoorStateQueryVariables>) {
        return ApolloReactHooks.useQuery<OwnerDoorStateQuery, OwnerDoorStateQueryVariables>(OwnerDoorStateDocument, baseOptions);
      }
export function useOwnerDoorStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OwnerDoorStateQuery, OwnerDoorStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OwnerDoorStateQuery, OwnerDoorStateQueryVariables>(OwnerDoorStateDocument, baseOptions);
        }
export type OwnerDoorStateQueryHookResult = ReturnType<typeof useOwnerDoorStateQuery>;
export type OwnerDoorStateLazyQueryHookResult = ReturnType<typeof useOwnerDoorStateLazyQuery>;
export type OwnerDoorStateQueryResult = ApolloReactCommon.QueryResult<OwnerDoorStateQuery, OwnerDoorStateQueryVariables>;
export const InspectorInspectionActivityDocument = gql`
    query inspectorInspectionActivity($reportPeriodFrom: DateTime!, $reportPeriodTo: DateTime!, $siteId: ID) {
  inspectorInspectionActivity(siteId: $siteId, reportPeriodFrom: $reportPeriodFrom, reportPeriodTo: $reportPeriodTo) {
    path
  }
}
    `;

/**
 * __useInspectorInspectionActivityQuery__
 *
 * To run a query within a React component, call `useInspectorInspectionActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspectorInspectionActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspectorInspectionActivityQuery({
 *   variables: {
 *      reportPeriodFrom: // value for 'reportPeriodFrom'
 *      reportPeriodTo: // value for 'reportPeriodTo'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useInspectorInspectionActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InspectorInspectionActivityQuery, InspectorInspectionActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<InspectorInspectionActivityQuery, InspectorInspectionActivityQueryVariables>(InspectorInspectionActivityDocument, baseOptions);
      }
export function useInspectorInspectionActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InspectorInspectionActivityQuery, InspectorInspectionActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InspectorInspectionActivityQuery, InspectorInspectionActivityQueryVariables>(InspectorInspectionActivityDocument, baseOptions);
        }
export type InspectorInspectionActivityQueryHookResult = ReturnType<typeof useInspectorInspectionActivityQuery>;
export type InspectorInspectionActivityLazyQueryHookResult = ReturnType<typeof useInspectorInspectionActivityLazyQuery>;
export type InspectorInspectionActivityQueryResult = ApolloReactCommon.QueryResult<InspectorInspectionActivityQuery, InspectorInspectionActivityQueryVariables>;
export const InstallerInstallationActivityDocument = gql`
    query installerInstallationActivity($reportPeriodFrom: DateTime!, $reportPeriodTo: DateTime!, $siteId: ID) {
  installerInstallationActivity(siteId: $siteId, reportPeriodFrom: $reportPeriodFrom, reportPeriodTo: $reportPeriodTo) {
    path
  }
}
    `;

/**
 * __useInstallerInstallationActivityQuery__
 *
 * To run a query within a React component, call `useInstallerInstallationActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstallerInstallationActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstallerInstallationActivityQuery({
 *   variables: {
 *      reportPeriodFrom: // value for 'reportPeriodFrom'
 *      reportPeriodTo: // value for 'reportPeriodTo'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useInstallerInstallationActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InstallerInstallationActivityQuery, InstallerInstallationActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<InstallerInstallationActivityQuery, InstallerInstallationActivityQueryVariables>(InstallerInstallationActivityDocument, baseOptions);
      }
export function useInstallerInstallationActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InstallerInstallationActivityQuery, InstallerInstallationActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InstallerInstallationActivityQuery, InstallerInstallationActivityQueryVariables>(InstallerInstallationActivityDocument, baseOptions);
        }
export type InstallerInstallationActivityQueryHookResult = ReturnType<typeof useInstallerInstallationActivityQuery>;
export type InstallerInstallationActivityLazyQueryHookResult = ReturnType<typeof useInstallerInstallationActivityLazyQuery>;
export type InstallerInstallationActivityQueryResult = ApolloReactCommon.QueryResult<InstallerInstallationActivityQuery, InstallerInstallationActivityQueryVariables>;
export const OwnerInstallationActivityDocument = gql`
    query ownerInstallationActivity($reportPeriodFrom: DateTime!, $reportPeriodTo: DateTime!, $siteId: ID) {
  ownerInstallationActivity(siteId: $siteId, reportPeriodFrom: $reportPeriodFrom, reportPeriodTo: $reportPeriodTo) {
    path
  }
}
    `;

/**
 * __useOwnerInstallationActivityQuery__
 *
 * To run a query within a React component, call `useOwnerInstallationActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerInstallationActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerInstallationActivityQuery({
 *   variables: {
 *      reportPeriodFrom: // value for 'reportPeriodFrom'
 *      reportPeriodTo: // value for 'reportPeriodTo'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useOwnerInstallationActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OwnerInstallationActivityQuery, OwnerInstallationActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<OwnerInstallationActivityQuery, OwnerInstallationActivityQueryVariables>(OwnerInstallationActivityDocument, baseOptions);
      }
export function useOwnerInstallationActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OwnerInstallationActivityQuery, OwnerInstallationActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OwnerInstallationActivityQuery, OwnerInstallationActivityQueryVariables>(OwnerInstallationActivityDocument, baseOptions);
        }
export type OwnerInstallationActivityQueryHookResult = ReturnType<typeof useOwnerInstallationActivityQuery>;
export type OwnerInstallationActivityLazyQueryHookResult = ReturnType<typeof useOwnerInstallationActivityLazyQuery>;
export type OwnerInstallationActivityQueryResult = ApolloReactCommon.QueryResult<OwnerInstallationActivityQuery, OwnerInstallationActivityQueryVariables>;
export const RequestAccountDocument = gql`
    mutation requestAccount($requestAccountInput: RequestAccountInput!) {
  requestAccount(requestAccountInput: $requestAccountInput)
}
    `;
export type RequestAccountMutationFn = ApolloReactCommon.MutationFunction<RequestAccountMutation, RequestAccountMutationVariables>;

/**
 * __useRequestAccountMutation__
 *
 * To run a mutation, you first call `useRequestAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAccountMutation, { data, loading, error }] = useRequestAccountMutation({
 *   variables: {
 *      requestAccountInput: // value for 'requestAccountInput'
 *   },
 * });
 */
export function useRequestAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestAccountMutation, RequestAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestAccountMutation, RequestAccountMutationVariables>(RequestAccountDocument, baseOptions);
      }
export type RequestAccountMutationHookResult = ReturnType<typeof useRequestAccountMutation>;
export type RequestAccountMutationResult = ApolloReactCommon.MutationResult<RequestAccountMutation>;
export type RequestAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestAccountMutation, RequestAccountMutationVariables>;
export const RolesByCompanyDocument = gql`
    query rolesByCompany($id: ID!) {
  rolesByCompany(id: $id) {
    id
    code
    name
  }
}
    `;

/**
 * __useRolesByCompanyQuery__
 *
 * To run a query within a React component, call `useRolesByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesByCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRolesByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RolesByCompanyQuery, RolesByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<RolesByCompanyQuery, RolesByCompanyQueryVariables>(RolesByCompanyDocument, baseOptions);
      }
export function useRolesByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RolesByCompanyQuery, RolesByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RolesByCompanyQuery, RolesByCompanyQueryVariables>(RolesByCompanyDocument, baseOptions);
        }
export type RolesByCompanyQueryHookResult = ReturnType<typeof useRolesByCompanyQuery>;
export type RolesByCompanyLazyQueryHookResult = ReturnType<typeof useRolesByCompanyLazyQuery>;
export type RolesByCompanyQueryResult = ApolloReactCommon.QueryResult<RolesByCompanyQuery, RolesByCompanyQueryVariables>;
export const RolesDocument = gql`
    query roles {
  roles {
    id
    code
    name
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        return ApolloReactHooks.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
      }
export function useRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = ApolloReactCommon.QueryResult<RolesQuery, RolesQueryVariables>;
export const SiteDocument = gql`
    query site($id: ID!) {
  site(id: $id) {
    id
    name
    reference
    ownedBy {
      id
      name
    }
    siteContact {
      id
      name
      phoneNumber
      mobileNumber
      emailAddress
    }
    documents {
      id
      originalFilename
      description
      documentType {
        id
        code
        name
      }
    }
    areas
    addressOne
    addressTwo
    city
    postcode
  }
}
    `;

/**
 * __useSiteQuery__
 *
 * To run a query within a React component, call `useSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SiteQuery, SiteQueryVariables>) {
        return ApolloReactHooks.useQuery<SiteQuery, SiteQueryVariables>(SiteDocument, baseOptions);
      }
export function useSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SiteQuery, SiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SiteQuery, SiteQueryVariables>(SiteDocument, baseOptions);
        }
export type SiteQueryHookResult = ReturnType<typeof useSiteQuery>;
export type SiteLazyQueryHookResult = ReturnType<typeof useSiteLazyQuery>;
export type SiteQueryResult = ApolloReactCommon.QueryResult<SiteQuery, SiteQueryVariables>;
export const SiteDoorsDocument = gql`
    query siteDoors($skip: Int, $take: Int, $sortField: String, $sortDirection: String, $statuses: [String!], $active: Boolean, $siteId: ID, $withActiveAssignments: Boolean!, $installationFrom: DateTime, $installationTo: DateTime, $inspectionFrom: DateTime, $inspectionTo: DateTime, $doorsTextFilter: String, $locationTypes: [String!]) {
  doors(skip: $skip, take: $take, sortField: $sortField, sortDirection: $sortDirection, statuses: $statuses, active: $active, siteId: $siteId, installationFrom: $installationFrom, installationTo: $installationTo, inspectionFrom: $inspectionFrom, inspectionTo: $inspectionTo, doorsTextFilter: $doorsTextFilter, locationTypes: $locationTypes) {
    items {
      id
      locationOrDoorRef
      tagId
      latestInstallationDate
      lastestInstallationInstalledBy
      nextInspectionDate
      transferredToFacilityManagementAt
      inspectionFrequency
      status {
        name
        code
      }
      activeDoorAssignments @include(if: $withActiveAssignments) {
        contractorCompany {
          id
          name
        }
        doorAssignmentType {
          code
        }
        workType {
          code
          name
        }
      }
    }
    total
    hasMore
  }
}
    `;

/**
 * __useSiteDoorsQuery__
 *
 * To run a query within a React component, call `useSiteDoorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteDoorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteDoorsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *      statuses: // value for 'statuses'
 *      active: // value for 'active'
 *      siteId: // value for 'siteId'
 *      withActiveAssignments: // value for 'withActiveAssignments'
 *      installationFrom: // value for 'installationFrom'
 *      installationTo: // value for 'installationTo'
 *      inspectionFrom: // value for 'inspectionFrom'
 *      inspectionTo: // value for 'inspectionTo'
 *      doorsTextFilter: // value for 'doorsTextFilter'
 *      locationTypes: // value for 'locationTypes'
 *   },
 * });
 */
export function useSiteDoorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SiteDoorsQuery, SiteDoorsQueryVariables>) {
        return ApolloReactHooks.useQuery<SiteDoorsQuery, SiteDoorsQueryVariables>(SiteDoorsDocument, baseOptions);
      }
export function useSiteDoorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SiteDoorsQuery, SiteDoorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SiteDoorsQuery, SiteDoorsQueryVariables>(SiteDoorsDocument, baseOptions);
        }
export type SiteDoorsQueryHookResult = ReturnType<typeof useSiteDoorsQuery>;
export type SiteDoorsLazyQueryHookResult = ReturnType<typeof useSiteDoorsLazyQuery>;
export type SiteDoorsQueryResult = ApolloReactCommon.QueryResult<SiteDoorsQuery, SiteDoorsQueryVariables>;
export const SitesDocument = gql`
    query sites($skip: Int, $take: Int, $sortField: String, $sortDirection: String, $textFilter: String) {
  sites(skip: $skip, take: $take, sortField: $sortField, sortDirection: $sortDirection, textFilter: $textFilter) {
    items {
      id
      name
      reference
      ownedBy {
        id
        name
      }
      areas
      addressOne
      addressTwo
      city
      postcode
      installedDoorCount
    }
    total
    hasMore
  }
}
    `;

/**
 * __useSitesQuery__
 *
 * To run a query within a React component, call `useSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *      textFilter: // value for 'textFilter'
 *   },
 * });
 */
export function useSitesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SitesQuery, SitesQueryVariables>) {
        return ApolloReactHooks.useQuery<SitesQuery, SitesQueryVariables>(SitesDocument, baseOptions);
      }
export function useSitesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SitesQuery, SitesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SitesQuery, SitesQueryVariables>(SitesDocument, baseOptions);
        }
export type SitesQueryHookResult = ReturnType<typeof useSitesQuery>;
export type SitesLazyQueryHookResult = ReturnType<typeof useSitesLazyQuery>;
export type SitesQueryResult = ApolloReactCommon.QueryResult<SitesQuery, SitesQueryVariables>;
export const AddSiteDocument = gql`
    mutation addSite($siteInput: SiteInput!) {
  addSite(siteInput: $siteInput)
}
    `;
export type AddSiteMutationFn = ApolloReactCommon.MutationFunction<AddSiteMutation, AddSiteMutationVariables>;

/**
 * __useAddSiteMutation__
 *
 * To run a mutation, you first call `useAddSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSiteMutation, { data, loading, error }] = useAddSiteMutation({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useAddSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSiteMutation, AddSiteMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSiteMutation, AddSiteMutationVariables>(AddSiteDocument, baseOptions);
      }
export type AddSiteMutationHookResult = ReturnType<typeof useAddSiteMutation>;
export type AddSiteMutationResult = ApolloReactCommon.MutationResult<AddSiteMutation>;
export type AddSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSiteMutation, AddSiteMutationVariables>;
export const UserDocument = gql`
    query User($id: Float!) {
  user(id: $id) {
    email
    id
    fullName
    roles {
      ...Role
    }
  }
}
    ${RoleFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = ApolloReactCommon.QueryResult<UserQuery, UserQueryVariables>;
export const LoggedInDocument = gql`
    query LoggedIn {
  loggedIn {
    id
    fullName
    email
    company {
      id
      name
      canReportInstallations
    }
    roles {
      ...Role
    }
  }
}
    ${RoleFragmentDoc}`;

/**
 * __useLoggedInQuery__
 *
 * To run a query within a React component, call `useLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInQuery, LoggedInQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInQuery, LoggedInQueryVariables>(LoggedInDocument, baseOptions);
      }
export function useLoggedInLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInQuery, LoggedInQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInQuery, LoggedInQueryVariables>(LoggedInDocument, baseOptions);
        }
export type LoggedInQueryHookResult = ReturnType<typeof useLoggedInQuery>;
export type LoggedInLazyQueryHookResult = ReturnType<typeof useLoggedInLazyQuery>;
export type LoggedInQueryResult = ApolloReactCommon.QueryResult<LoggedInQuery, LoggedInQueryVariables>;
export const AcceptInviteDocument = gql`
    mutation acceptInvite($acceptInviteInput: AcceptInviteInput!) {
  acceptInvite(acceptInviteInput: $acceptInviteInput) {
    code
  }
}
    `;
export type AcceptInviteMutationFn = ApolloReactCommon.MutationFunction<AcceptInviteMutation, AcceptInviteMutationVariables>;

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      acceptInviteInput: // value for 'acceptInviteInput'
 *   },
 * });
 */
export function useAcceptInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptInviteMutation, AcceptInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(AcceptInviteDocument, baseOptions);
      }
export type AcceptInviteMutationHookResult = ReturnType<typeof useAcceptInviteMutation>;
export type AcceptInviteMutationResult = ApolloReactCommon.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<AcceptInviteMutation, AcceptInviteMutationVariables>;
export const DisableUsersDocument = gql`
    mutation disableUsers($idListInput: IdListInput!) {
  disableUsers(idListInput: $idListInput)
}
    `;
export type DisableUsersMutationFn = ApolloReactCommon.MutationFunction<DisableUsersMutation, DisableUsersMutationVariables>;

/**
 * __useDisableUsersMutation__
 *
 * To run a mutation, you first call `useDisableUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUsersMutation, { data, loading, error }] = useDisableUsersMutation({
 *   variables: {
 *      idListInput: // value for 'idListInput'
 *   },
 * });
 */
export function useDisableUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisableUsersMutation, DisableUsersMutationVariables>) {
        return ApolloReactHooks.useMutation<DisableUsersMutation, DisableUsersMutationVariables>(DisableUsersDocument, baseOptions);
      }
export type DisableUsersMutationHookResult = ReturnType<typeof useDisableUsersMutation>;
export type DisableUsersMutationResult = ApolloReactCommon.MutationResult<DisableUsersMutation>;
export type DisableUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableUsersMutation, DisableUsersMutationVariables>;
export const EnableUsersDocument = gql`
    mutation enableUsers($idListInput: IdListInput!) {
  enableUsers(idListInput: $idListInput)
}
    `;
export type EnableUsersMutationFn = ApolloReactCommon.MutationFunction<EnableUsersMutation, EnableUsersMutationVariables>;

/**
 * __useEnableUsersMutation__
 *
 * To run a mutation, you first call `useEnableUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableUsersMutation, { data, loading, error }] = useEnableUsersMutation({
 *   variables: {
 *      idListInput: // value for 'idListInput'
 *   },
 * });
 */
export function useEnableUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnableUsersMutation, EnableUsersMutationVariables>) {
        return ApolloReactHooks.useMutation<EnableUsersMutation, EnableUsersMutationVariables>(EnableUsersDocument, baseOptions);
      }
export type EnableUsersMutationHookResult = ReturnType<typeof useEnableUsersMutation>;
export type EnableUsersMutationResult = ApolloReactCommon.MutationResult<EnableUsersMutation>;
export type EnableUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableUsersMutation, EnableUsersMutationVariables>;
export const AddUserDocument = gql`
    mutation addUser($newUserInput: NewUserInput!) {
  addUser(newUserInput: $newUserInput) {
    id
  }
}
    `;
export type AddUserMutationFn = ApolloReactCommon.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      newUserInput: // value for 'newUserInput'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        return ApolloReactHooks.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, baseOptions);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = ApolloReactCommon.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const PasswordResetRequestDocument = gql`
    mutation passwordResetRequest($userEmail: String!) {
  passwordResetRequest(userEmail: $userEmail)
}
    `;
export type PasswordResetRequestMutationFn = ApolloReactCommon.MutationFunction<PasswordResetRequestMutation, PasswordResetRequestMutationVariables>;

/**
 * __usePasswordResetRequestMutation__
 *
 * To run a mutation, you first call `usePasswordResetRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetRequestMutation, { data, loading, error }] = usePasswordResetRequestMutation({
 *   variables: {
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function usePasswordResetRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PasswordResetRequestMutation, PasswordResetRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<PasswordResetRequestMutation, PasswordResetRequestMutationVariables>(PasswordResetRequestDocument, baseOptions);
      }
export type PasswordResetRequestMutationHookResult = ReturnType<typeof usePasswordResetRequestMutation>;
export type PasswordResetRequestMutationResult = ApolloReactCommon.MutationResult<PasswordResetRequestMutation>;
export type PasswordResetRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<PasswordResetRequestMutation, PasswordResetRequestMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($resetPasswordInput: ResetPasswordInput!) {
  resetPassword(resetPasswordInput: $resetPasswordInput) {
    code
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPasswordInput: // value for 'resetPasswordInput'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const EditUserDocument = gql`
    mutation editUser($editUserInput: EditUserInput!) {
  editUser(editUserInput: $editUserInput)
}
    `;
export type EditUserMutationFn = ApolloReactCommon.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      editUserInput: // value for 'editUserInput'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        return ApolloReactHooks.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, baseOptions);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = ApolloReactCommon.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = ApolloReactCommon.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const UsersDocument = gql`
    query users($skip: Int, $take: Int, $sortField: String, $sortDirection: String, $companyId: ID!, $includeDeleted: Boolean!) {
  users(skip: $skip, take: $take, sortField: $sortField, sortDirection: $sortDirection, companyId: $companyId, includeDeleted: $includeDeleted) {
    items {
      id
      fullName
      email
      addedBy {
        fullName
        roles {
          name
        }
      }
      roles {
        ...Role
      }
      deletedAt
    }
    total
    hasMore
  }
}
    ${RoleFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *      companyId: // value for 'companyId'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>;
export const CompanyUsersByEmailDocument = gql`
    query companyUsersByEmail($email: String!, $companyId: ID!, $includeDeleted: Boolean) {
  companyUsersByEmail(email: $email, companyId: $companyId, includeDeleted: $includeDeleted) {
    id
    email
  }
}
    `;

/**
 * __useCompanyUsersByEmailQuery__
 *
 * To run a query within a React component, call `useCompanyUsersByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUsersByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUsersByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *      companyId: // value for 'companyId'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useCompanyUsersByEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyUsersByEmailQuery, CompanyUsersByEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyUsersByEmailQuery, CompanyUsersByEmailQueryVariables>(CompanyUsersByEmailDocument, baseOptions);
      }
export function useCompanyUsersByEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyUsersByEmailQuery, CompanyUsersByEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyUsersByEmailQuery, CompanyUsersByEmailQueryVariables>(CompanyUsersByEmailDocument, baseOptions);
        }
export type CompanyUsersByEmailQueryHookResult = ReturnType<typeof useCompanyUsersByEmailQuery>;
export type CompanyUsersByEmailLazyQueryHookResult = ReturnType<typeof useCompanyUsersByEmailLazyQuery>;
export type CompanyUsersByEmailQueryResult = ApolloReactCommon.QueryResult<CompanyUsersByEmailQuery, CompanyUsersByEmailQueryVariables>;
export const CompanyAdminsByNameDocument = gql`
    query companyAdminsByName($name: String!, $companyId: ID!, $take: Int!) {
  companyAdminsByName(name: $name, companyId: $companyId, take: $take) {
    id
    fullName
    email
  }
}
    `;

/**
 * __useCompanyAdminsByNameQuery__
 *
 * To run a query within a React component, call `useCompanyAdminsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyAdminsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyAdminsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      companyId: // value for 'companyId'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanyAdminsByNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyAdminsByNameQuery, CompanyAdminsByNameQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyAdminsByNameQuery, CompanyAdminsByNameQueryVariables>(CompanyAdminsByNameDocument, baseOptions);
      }
export function useCompanyAdminsByNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyAdminsByNameQuery, CompanyAdminsByNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyAdminsByNameQuery, CompanyAdminsByNameQueryVariables>(CompanyAdminsByNameDocument, baseOptions);
        }
export type CompanyAdminsByNameQueryHookResult = ReturnType<typeof useCompanyAdminsByNameQuery>;
export type CompanyAdminsByNameLazyQueryHookResult = ReturnType<typeof useCompanyAdminsByNameLazyQuery>;
export type CompanyAdminsByNameQueryResult = ApolloReactCommon.QueryResult<CompanyAdminsByNameQuery, CompanyAdminsByNameQueryVariables>;