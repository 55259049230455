import React from 'react';

type Props = {
  size?: number;
};
const PersonOff = ({ size = 24 }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16637 16.6667C3.93026 16.6667 3.73221 16.5867 3.57221 16.4267C3.41276 16.2672 3.33304 16.0694 3.33304 15.8333V14.3333C3.33304 13.8611 3.45471 13.4269 3.69804 13.0308C3.94082 12.6353 4.2636 12.3333 4.66637 12.125C5.29137 11.8056 5.92693 11.5486 6.57304 11.3542C7.2186 11.1597 7.87471 11.0139 8.54137 10.9167L1.72887 4.10417C1.56221 3.9375 1.48249 3.74306 1.48971 3.52083C1.49637 3.29861 1.58304 3.10417 1.74971 2.9375C1.91637 2.77083 2.11415 2.6875 2.34304 2.6875C2.57249 2.6875 2.77054 2.77083 2.93721 2.9375L17.083 17.0833C17.2497 17.25 17.333 17.4444 17.333 17.6667C17.333 17.8889 17.2497 18.0833 17.083 18.25C16.9164 18.4167 16.7186 18.5 16.4897 18.5C16.2603 18.5 16.0622 18.4167 15.8955 18.25L14.2914 16.6667H4.16637ZM16.6664 14.2917L13.8747 11.5C14.1247 11.5972 14.3714 11.6944 14.6147 11.7917C14.8575 11.8889 15.0969 12 15.333 12.125C15.7358 12.3194 16.0553 12.6147 16.2914 13.0108C16.5275 13.4064 16.6525 13.8333 16.6664 14.2917ZM11.833 9.45833L7.20804 4.83333C7.52749 4.36111 7.93026 3.99306 8.41637 3.72917C8.90249 3.46528 9.43026 3.33333 9.99971 3.33333C10.9164 3.33333 11.7011 3.65972 12.3539 4.3125C13.0067 4.96528 13.333 5.75 13.333 6.66667C13.333 7.23611 13.2011 7.76389 12.9372 8.25C12.6733 8.73611 12.3053 9.13889 11.833 9.45833Z"
        fill="white"
      />
    </svg>
  );
};

export default PersonOff;
