import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import Layout from '../components/Layout';
import { useDoorQuery, useEditDoorMutation, DoorFragment, DoorInput, useDoorByTagIdQuery } from '../queries';
import * as Routes from '../constants/Routes';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import useDialog from '../hooks/useDialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DoorDetails from '../components/manufactured-doors/view/DoorDetails';
import DoorPartsList from '../components/manufactured-doors/view/DoorPartsList';
import DoorFiles from '../components/manufactured-doors/view/DoorFiles';
import {
  Photo,
  InstallationGuide,
  FireCertificate,
  Warranty,
  NoAccessPhoto,
  doorDocumentTypes,
} from '../constants/DocumentTypes';
import Loading from './Loading';
import { useSnackbar } from 'notistack';
import DoorDetailsFields from '../components/manufactured-doors/add/DoorDetailsFields';
import { FormProvider, useForm } from 'react-hook-form';
import DoorPartsListFields from '../components/manufactured-doors/add/DoorPartsListFields';
import { DoorFormData, DoorPhoto, DoorDocument } from './DoorAdd';
import { DoorLeaf } from '../components/manufactured-doors/add/DoorDetailsAdd';
import { removeEmptyFields } from '../utils/FormHelpers';
import DoorPhotosUpload from '../components/manufactured-doors/add/DoorPhotosUpload';
import DoorDocumentsUpload from '../components/manufactured-doors/add/DoorDocumentsUpload';
import LoadingButton from '../components/LoadingButton';
import DoorHistory from '../components/manufactured-doors/view/history/DoorHistory';
import MaterialLink from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import ProtectedSection from '../components/ProtectedSection';
import Roles from '../constants/Roles';
import DoorStatus from '../constants/Status';
import { GraphQLError } from 'graphql/error';

interface DoorParams {
  id?: string;
  tagId?: string;
}

const translateDoor = (door: DoorFragment): DoorFormData => {
  const newFormData: DoorFormData = {
    id: door.id,
    additionalComments: door.model?.doorModelParts?.additionalComments || door.doorParts?.additionalComments,
    chain: door.doorParts?.chain,
    closer: door.model?.doorModelParts?.closer || door.doorParts?.closer,
    cylinder: door.model?.doorModelParts?.cylinder || door.doorParts?.cylinder,
    dropSeal: door.model?.doorModelParts?.dropSeal || door.doorParts?.dropSeal,
    fdRatingId: door.model?.fdRating?.id || door.fdRating?.id,
    handles: door.model?.doorModelParts?.handles || door.doorParts?.handles,
    hinges: door.model?.doorModelParts?.hinges || door.doorParts?.hinges,
    intumescentStrip: door.model?.doorModelParts?.intumescentStrip || door.doorParts?.intumescentStrip,
    doorReference: door.doorReference,
    keeps: door.doorParts?.keeps,
    letterbox: door.model?.doorModelParts?.letterbox || door.doorParts?.letterbox,
    lockLatch: door.model?.doorModelParts?.lockLatch || door.doorParts?.lockLatch,
    numerals: door.doorParts?.numerals,
    projectReference: door.projectReference,
    smokeSeals: door.model?.doorModelParts?.smokeSeals || door.doorParts?.smokeSeals,
    spyhole: door.model?.doorModelParts?.spyhole || door.doorParts?.spyhole,
    tagId: door.tagId,
    barcode: door.barcode,
    lineBarcode: door.lineBarcode,
    thresholdStrip: door.model?.doorModelParts?.thresholdStrip || door.doorParts?.thresholdStrip,
    weatherSeals: door.doorParts?.weatherSeals,
    inspectionFrequency: door.inspectionFrequency,
  };

  const leafs: DoorLeaf[] = [
    {
      acousticRating: door.leaf1AccousticRating,
      coreSupplier: door.model?.coreSupplier || door.leaf1CoreSupplier,
      height: Number(door.leaf1Height) || undefined,
      width: Number(door.leaf1Width) || undefined,
    },
  ];

  if (door.leaf2AccousticRating || door.leaf2CoreSupplier || door.leaf2Height || door.leaf2Width) {
    leafs.push({
      acousticRating: door.leaf2AccousticRating,
      coreSupplier: door.model?.coreSupplier || door.leaf2CoreSupplier,
      height: Number(door.leaf2Height) || undefined,
      width: Number(door.leaf2Width) || undefined,
    });
  }

  newFormData.doorLeafs = leafs;
  newFormData.doorModelId = Number(door.model?.id) ?? undefined;

  const existingPhotos = door.documents?.filter((d) => d.documentType.code === Photo);
  if (existingPhotos && existingPhotos.length > 0) {
    const translatedPhotos: DoorPhoto[] = existingPhotos.map((p) => ({
      id: p.id,
      description: p.description,
      file: {
        name: p.originalFilename,
      },
    }));

    newFormData.photos = translatedPhotos;
  }
  const existingDocuments = door.documents?.filter(
    (d) => d.documentType.code !== Photo && d.documentType.code !== NoAccessPhoto
  );
  if (existingDocuments && existingDocuments.length > 0) {
    const translatedDocuments: DoorDocument[] = existingDocuments.map((p) => ({
      id: p.id,
      documentTypeCode: p.documentType.code,
      file: {
        name: p.originalFilename,
      },
    }));

    newFormData.documents = translatedDocuments;
  }

  return newFormData;
};

export default function Door() {
  const { showDialog } = useDialog();
  const history = useHistory();
  const { id, tagId } = useParams<DoorParams>();
  const [tabIndex, setTabIndex] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [doorDetails, setDoorDetails] = useState<DoorFragment | undefined>();
  const [formData, setFormData] = useState<DoorFormData>({});
  const [photos, setPhotos] = useState<DoorPhoto[]>([]);
  const [documents, setDocuments] = useState<DoorDocument[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [editDoorMutation, { loading: savingDoor, data: savedDoor }] = useEditDoorMutation();
  const { data: dataById, loading: loadingById, error: errorById } = useDoorQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: String(id ?? 0),
    },
    skip: id === undefined,
  });

  const { data: dataByTag, loading: loadingByTag, error: errorByTag } = useDoorByTagIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      tagId: String(tagId ?? 0),
    },
    skip: tagId === undefined,
  });

  const door = dataById?.door ?? dataByTag?.doorByTagId;
  const loading = loadingById ?? loadingByTag;
  const error = errorById ?? errorByTag;

  const editable = door?.status.code !== DoorStatus.Retired;

  useEffect(() => {
    if (error) {
      let errorMessage = 'There has been an unexpected error.';
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        errorMessage = error.graphQLErrors[0].message;
      }
      console.log(error);
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  const form = useForm<DoorFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { handleSubmit, getValues, reset, formState, trigger } = form;

  useEffect(() => {
    if (door) {
      const newFormData = translateDoor(door);
      setPhotos(newFormData.photos ?? []);
      setDocuments(newFormData.documents ?? []);
      setFormData(newFormData);
      reset(newFormData);
      setDoorDetails(door);
    }
  }, [door, reset]);

  useEffect(() => {
    if (savedDoor) {
      const newFormData = translateDoor(savedDoor?.addDoor);
      setPhotos(newFormData.photos ?? []);
      setDocuments(newFormData.documents ?? []);
      setFormData(newFormData);
      reset(newFormData);
      setDoorDetails(savedDoor.addDoor);
    }
  }, [savedDoor, reset]);

  const getBreadcrumb = () => {
    return (
      <ProtectedSection roles={[Roles.ManufacturerAdmin, Roles.InstallerAdmin]}>
        <Breadcrumbs aria-label="breadcrumb">
          <MaterialLink color="inherit" component={Link} to={Routes.ManufacturedDoors}>
            <Typography variant="body2">Manufactured doors</Typography>
          </MaterialLink>
          <Typography variant="body2">{id}</Typography>
        </Breadcrumbs>
      </ProtectedSection>
    );
  };

  const onTabChange = (_: any, value: number) => {
    if (isEdit) {
      const values = getValues() as DoorFormData;
      // save the form values
      setFormData((prev) => ({ ...prev, ...values }));
      reset(formData);
    }
    setTabIndex(value);
  };

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => {
        if (isEdit) {
          setIsEdit(false);
        } else {
          history.goBack();
        }
      },
    });
  };

  const saveChanges = async () => {
    const values = getValues() as any;
    const completeFormData: DoorInput = {
      ...formData,
      ...values,
      photos: photos.map((d) => ({
        id: d.id,
        description: d.description,
        file: d.id ? null : d.file, // send the file object for new files only or they will not match the FileUpload type
      })),
      documents: documents.map((d) => ({
        id: d.id,
        documentTypeCode: d.documentTypeCode,
        file: d.id ? null : d.file, // send the file object for new files only or they will not match the FileUpload type
      })),
    };
    // Hack
    delete (completeFormData as any).documentTypesAttached;
    removeEmptyFields(completeFormData);

    completeFormData.fdRatingId = completeFormData.fdRatingId && Number(completeFormData.fdRatingId);
    completeFormData.inspectionFrequency =
      completeFormData.inspectionFrequency && Number(completeFormData.inspectionFrequency);
    if (completeFormData.doorLeafs && completeFormData.doorLeafs.length > 0) {
      completeFormData.doorLeafs[0].height =
        completeFormData.doorLeafs[0].height && Number(completeFormData.doorLeafs[0].height);
      completeFormData.doorLeafs[0].width =
        completeFormData.doorLeafs[0].width && Number(completeFormData.doorLeafs[0].width);
      if (completeFormData.doorLeafs.length === 2) {
        completeFormData.doorLeafs[1].height =
          completeFormData.doorLeafs[1].height && Number(completeFormData.doorLeafs[1].height);
        completeFormData.doorLeafs[1].width =
          completeFormData.doorLeafs[1].width && Number(completeFormData.doorLeafs[1].width);
      }
    }

    try {
      await editDoorMutation({
        variables: {
          // @ts-ignore
          door: completeFormData!,
        },
      });
      enqueueSnackbar('Door saved successfully', { variant: 'success' });
      setIsEdit(false);
    } catch (e) {
      e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
        if (gqlerror.extensions?.code === 'BARCODE_IN_USE') {
          enqueueSnackbar(`Barcode ${completeFormData.barcode} is already in use`, { variant: 'error' });
        } else {
          enqueueSnackbar('There was an error attempting to save this door', { variant: 'error' });
        }
      });
    }
  };

  const onFormConfirm = (values: any) => {
    saveChanges();
  };

  const onEditClick = () => {
    setIsEdit(true);
    trigger();
  };

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5">{id}</Typography>
              {loading && <Skeleton width="175px" />}
              {!loading && getBreadcrumb()}
            </Box>
            <Box minWidth={96}>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>
          <Box mt={1}>
            <Paper>
              <Tabs
                value={tabIndex}
                onChange={onTabChange}
                aria-label="Door details"
                indicatorColor="primary"
                scrollButtons="auto"
                variant="fullWidth"
              >
                <Tab label="Details" />
                <Tab label="Parts list" />
                <Tab label="Documentation" />
                <Tab label="Photos" />
                <Tab label="History" />
              </Tabs>
              <Divider />
              {loading && <Loading />}
              {!loading && !error && !isEdit && tabIndex === 0 && <DoorDetails door={doorDetails} />}
              {!loading && !error && !isEdit && tabIndex === 1 && <DoorPartsList door={doorDetails} />}
              {!loading && !error && !isEdit && tabIndex === 2 && (
                <DoorFiles
                  door={doorDetails}
                  documentTypeCodes={doorDocumentTypes}
                  highlightMissingTypes={[InstallationGuide, FireCertificate, Warranty]}
                  noFilesMessage="There are no documents to display."
                />
              )}
              {!loading && !error && !isEdit && tabIndex === 3 && (
                <DoorFiles
                  door={doorDetails}
                  documentTypeCodes={[Photo]}
                  noFilesMessage="There are no photos to display."
                />
              )}

              {!loading && !error && !isEdit && tabIndex === 4 && <DoorHistory history={doorDetails?.history} />}

              {isEdit && (
                <Box p={5}>
                  <FormProvider {...form}>
                    <form id="edit-door" onSubmit={handleSubmit(onFormConfirm)}>
                      {!loading && !error && tabIndex === 0 && (
                        <DoorDetailsFields
                          isEdit
                          doorModel={doorDetails?.model}
                          doorStatus={doorDetails?.status}
                          doorFdRatingId={door?.fdRating?.id}
                          readOnly={door?.readOnly}
                        />
                      )}
                      {!loading && !error && tabIndex === 1 && (
                        <DoorPartsListFields
                          doorModelParts={doorDetails?.model?.doorModelParts}
                          readOnly={door?.readOnly}
                          doorParts={door?.doorParts}
                        />
                      )}
                      {!loading && !error && tabIndex === 2 && (
                        <DoorDocumentsUpload
                          documents={documents}
                          setDocuments={setDocuments}
                          modelDocuments={doorDetails?.model?.documents}
                          documentTypeCodes={doorDocumentTypes}
                        />
                      )}
                      {!loading && !error && tabIndex === 3 && (
                        <DoorPhotosUpload photos={photos} setPhotos={setPhotos} />
                      )}
                    </form>
                  </FormProvider>
                </Box>
              )}
              <Box display="flex" justifyContent="flex-end" p={5} pt={0}>
                <ProtectedSection
                  roles={[
                    Roles.ManufacturerAdmin,
                    Roles.SuperUser,
                    Roles.InstallerAdmin,
                    Roles.FacilityManager,
                    Roles.FacilityManagerAdmin,
                  ]}
                >
                  {!isEdit && tabIndex !== 4 && editable && (
                    <Button variant="contained" color="primary" onClick={onEditClick}>
                      Edit
                    </Button>
                  )}
                  {isEdit && tabIndex !== 4 && (
                    <LoadingButton
                      loading={savingDoor}
                      disabled={!formState.isValid || savingDoor}
                      variant="contained"
                      color="primary"
                      type="submit"
                      form="edit-door"
                    >
                      Save
                    </LoadingButton>
                  )}
                </ProtectedSection>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Layout>
  );
}
