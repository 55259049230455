import Box from '@material-ui/core/Box';
import React from 'react';
import { DoorHistoryFragment } from '../../../../../queries';
import DoorHistoryDetailDialog from '../DoorHistoryDetailDialog';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import StandardGridItem from './StandardGridItem';
import theme from '../../../../../theme';

const useStyles = makeStyles({
  header: {
    color: '#fff',
    fontWeight: 500,
    letterSpacing: 0.2,
  },
  detailsHeader: {
    color: '#fff',
    paddingTop: theme.spacing(1.5),
  },
});

interface DoorUnassignedAdditionalInfo {
  removedContractorCompany: string;
  unassignedContracts: { contractorName: string; workType: string; contractType: string }[];
}

interface Props {
  open: boolean;
  onClose: () => void;
  history: DoorHistoryFragment;
}

interface DetailDialogProps {
  history: DoorHistoryFragment;
}

function DetailDialog({ history }: DetailDialogProps) {
  const styles = useStyles();
  if (!history.additionalInfoJson) {
    return <></>;
  }

  const additionalInfo = JSON.parse(history.additionalInfoJson) as DoorUnassignedAdditionalInfo;

  return (
    <Box pb={1}>
      <TableContainer style={{ padding: 0 }}>
        <Table style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ paddingLeft: 0 }}>
                <Typography variant="subtitle1" className={styles.header}>
                  Contractor name
                </Typography>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: 0 }}>
                <Typography variant="subtitle1" className={styles.header}>
                  Work type
                </Typography>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: 0 }}>
                <Typography variant="subtitle1" className={styles.header}>
                  Contract type
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {additionalInfo.unassignedContracts?.map((unassignedContract, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row" style={{ paddingLeft: 0 }}>
                  <Typography variant="subtitle1">{unassignedContract.contractorName}</Typography>
                </TableCell>
                <TableCell align="left" style={{ paddingRight: 0, whiteSpace: 'pre-wrap' }}>
                  <Typography variant="subtitle1">{unassignedContract.workType}</Typography>
                </TableCell>
                <TableCell align="left" style={{ paddingRight: 0, whiteSpace: 'pre-wrap' }}>
                  <Typography variant="subtitle1">{unassignedContract.contractType}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default function DoorAssignedDetailDialog({ open, onClose, history }: Props) {
  let additionalInfo: DoorUnassignedAdditionalInfo | undefined = undefined;

  if (history.additionalInfoJson) {
    additionalInfo = JSON.parse(history.additionalInfoJson) as DoorUnassignedAdditionalInfo;
  }

  return (
    <DoorHistoryDetailDialog
      open={open}
      onClose={onClose}
      history={history}
      actorName="User"
      detailsLabel="Removed assignments"
      additionalHeaderItems={
        <>
          {additionalInfo && (
            <StandardGridItem
              title="Contractor removed"
              value={additionalInfo.removedContractorCompany}
              minWidth={12}
            />
          )}
        </>
      }
    >
      <DetailDialog history={history} />
    </DoorHistoryDetailDialog>
  );
}
