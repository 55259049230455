import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import CookiePopup from './components/CookiePopup';
import DoorAdd from './pages/DoorAdd';
import Roles from './constants/Roles';
import * as Routes from './constants/Routes';
import { AppContextProvider } from './context/app/AppContext';
import Companies from './pages/Companies';
import Company from './pages/Company';
import CompanyAddEdit from './pages/CompanyAddEdit';
import DoorModel from './pages/DoorModel';
import DoorModelAdd from './pages/DoorModelAdd';
import DoorModels from './pages/DoorModels';
import Home from './pages/Home';
import ImportDoors from './pages/ImportDoors';
import Login from './pages/Login';
import ManufacturedDoors from './pages/ManufacturedDoors';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProcessInvite from './pages/ProcessInvite';
import TermsAndConditions from './pages/TermsAndConditions';
import UserAddEdit from './pages/UserAddEdit';
import ProtectedRoute from './routing/ProtectedRoute';
import Door from './pages/Door';
import Site from './pages/Site';
import SiteAdd from './pages/SiteAdd';
import SiteEdit from './pages/SiteEdit';
import TransferDoors from './pages/TransferDoors';
import Welcome from './pages/Welcome';
import MySitesAdd from './pages/MySitesAdd';
import MySites from './pages/MySites';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import RetireDoors from './pages/RetireDoors';
import AccountInfo from './pages/AccountInfo';
import AssignDoorsToSite from './pages/AssignDoorsToSite';
import Billing from './pages/billing/Billing';
import AssignDoorsToContractor from './pages/AssignDoorsToContractor';
import RemoveDoorsContractor from './pages/RemoveDoorsContractor';
import ReportDoorInstallation from './pages/ReportDoorInstallation';
import Dashboard from './pages/Dashboard';
import RepairDoor from './pages/RepairDoor';
import Reports from './pages/Reports';

function App() {
  return (
    <AppContextProvider>
      <BrowserRouter>
        <Switch>
          <Route path={Routes.Login} component={Login} />
          <Route path={`${Routes.ProcessInvite}/:token`} component={ProcessInvite} />
          <Route path={Routes.ForgotPassword} component={ForgotPassword} />
          <Route path={`${Routes.ResetPassword}/:token`} component={ResetPassword} />
          <Route path={Routes.PrivacyPolicy} component={PrivacyPolicy} />
          <Route path={Routes.TermsAndConditions} component={TermsAndConditions} />
          <Route path={Routes.Welcome} component={Welcome} />
          <ProtectedRoute path={Routes.Home} component={Home} />
          <ProtectedRoute path={Routes.InviteCompany} component={CompanyAddEdit} roles={[Roles.SuperUser]} />
          <ProtectedRoute path={Routes.AccountInfo} component={AccountInfo} />
          <ProtectedRoute
            path={`${Routes.Companies}/:id/add-user`}
            component={UserAddEdit}
            roles={[
              Roles.ManufacturerAdmin,
              Roles.SuperUser,
              Roles.InstallerAdmin,
              Roles.InspectorAdmin,
              Roles.FacilityManagerAdmin,
              Roles.ServiceEngineerAdmin,
            ]}
          />
          <ProtectedRoute
            path={`${Routes.Companies}/:id/edit-user/:userId`}
            component={UserAddEdit}
            roles={[
              Roles.ManufacturerAdmin,
              Roles.SuperUser,
              Roles.InstallerAdmin,
              Roles.InspectorAdmin,
              Roles.FacilityManagerAdmin,
              Roles.ServiceEngineerAdmin,
            ]}
          />
          <ProtectedRoute
            path={`${Routes.Companies}/:id/edit`}
            component={CompanyAddEdit}
            roles={[
              Roles.ManufacturerAdmin,
              Roles.SuperUser,
              Roles.InstallerAdmin,
              Roles.InspectorAdmin,
              Roles.FacilityManagerAdmin,
              Roles.ServiceEngineerAdmin,
            ]}
          />
          <ProtectedRoute
            path={`${Routes.Companies}/:id`}
            component={Company}
            roles={[
              Roles.ManufacturerAdmin,
              Roles.SuperUser,
              Roles.InstallerAdmin,
              Roles.InspectorAdmin,
              Roles.FacilityManagerAdmin,
              Roles.ServiceEngineerAdmin,
            ]}
          />
          <ProtectedRoute path={Routes.Companies} component={Companies} roles={[Roles.SuperUser]} />
          <ProtectedRoute path={Routes.Billing} component={Billing} roles={[Roles.SuperUser]} />

          <ProtectedRoute
            path={`${Routes.ViewDoor}/:id`}
            component={Door}
            roles={[
              Roles.ManufacturerAdmin,
              Roles.SuperUser,
              Roles.InstallerAdmin,
              Roles.FacilityManager,
              Roles.FacilityManagerAdmin,
              Roles.InspectorAdmin,
              Roles.ServiceEngineerAdmin,
            ]}
          />

          <ProtectedRoute
            path={`${Routes.ViewDoorByTag}/:tagId`}
            component={Door}
            roles={[Roles.FacilityManager, Roles.FacilityManagerAdmin]}
          />

          <ProtectedRoute path={Routes.TransferDoors} component={TransferDoors} roles={[Roles.ManufacturerAdmin]} />
          <ProtectedRoute
            path={Routes.ManufacturedDoorsAdd}
            component={DoorAdd}
            roles={[Roles.ManufacturerAdmin, Roles.InstallerAdmin]}
          />
          <ProtectedRoute
            path={`${Routes.ManufacturedDoors}/assign-doors-to-site`}
            component={AssignDoorsToSite}
            roles={[Roles.InstallerAdmin, Roles.Installer]}
          />
          <ProtectedRoute
            path={Routes.ManufacturedDoors}
            component={ManufacturedDoors}
            roles={[Roles.ManufacturerAdmin, Roles.InstallerAdmin]}
          />

          <ProtectedRoute
            path={Routes.DoorModelAdd}
            component={DoorModelAdd}
            roles={[Roles.ManufacturerAdmin, Roles.InstallerAdmin]}
          />
          <ProtectedRoute
            path={`${Routes.DoorModels}/:id`}
            component={DoorModel}
            roles={[Roles.ManufacturerAdmin, Roles.InstallerAdmin]}
          />
          <ProtectedRoute
            path={Routes.DoorModels}
            component={DoorModels}
            roles={[Roles.ManufacturerAdmin, Roles.InstallerAdmin]}
          />
          <ProtectedRoute
            path={`${Routes.ImportDoors}`}
            component={ImportDoors}
            roles={[Roles.ManufacturerAdmin, Roles.InstallerAdmin]}
          />

          <ProtectedRoute
            path={Routes.SitesAdd}
            component={SiteAdd}
            roles={[Roles.InstallerAdmin, Roles.InspectorAdmin, Roles.FacilityManagerAdmin]}
          />
          <ProtectedRoute
            path={`${Routes.Sites}/:id/edit`}
            component={SiteEdit}
            roles={[Roles.InstallerAdmin, Roles.InspectorAdmin, Roles.FacilityManagerAdmin, Roles.FacilityManager]}
          />
          <ProtectedRoute
            path={`${Routes.Sites}/:id/transfer`}
            component={TransferDoors}
            roles={[Roles.InstallerAdmin]}
          />
          <ProtectedRoute
            path={`${Routes.Sites}/:id/assign-doors-to-contractor`}
            component={AssignDoorsToContractor}
            roles={[
              Roles.FacilityManagerAdmin,
              Roles.FacilityManager,
              Roles.InstallerAdmin,
              Roles.InspectorAdmin,
              Roles.ServiceEngineerAdmin,
            ]}
          />
          <ProtectedRoute
            path={`${Routes.Sites}/:id/remove-doors-contractor`}
            component={RemoveDoorsContractor}
            roles={[Roles.FacilityManagerAdmin]}
          />
          <ProtectedRoute
            path={`${Routes.Sites}/:siteId/report-installation/:doorId`}
            component={ReportDoorInstallation}
            roles={[Roles.InstallerAdmin]}
          />
          <ProtectedRoute
            path={`${Routes.Sites}/:id/retire-doors`}
            component={RetireDoors}
            roles={[Roles.FacilityManagerAdmin, Roles.FacilityManager]}
          />
          <ProtectedRoute path={`${Routes.Repair}/:id`} component={RepairDoor} roles={[Roles.FacilityManagerAdmin]} />
          <ProtectedRoute
            path={`${Routes.Sites}/:id/import-doors`}
            component={ImportDoors}
            roles={[Roles.FacilityManagerAdmin, Roles.FacilityManager]}
          />
          <ProtectedRoute
            path={`${Routes.Sites}/:id`}
            component={Site}
            roles={[
              Roles.InstallerAdmin,
              Roles.InspectorAdmin,
              Roles.FacilityManagerAdmin,
              Roles.FacilityManager,
              Roles.ServiceEngineerAdmin,
            ]}
          />
          <ProtectedRoute
            path={`${Routes.MySites}/add`}
            component={MySitesAdd}
            roles={[Roles.InstallerAdmin, Roles.InspectorAdmin]}
          />
          <ProtectedRoute
            path={Routes.MySites}
            component={MySites}
            roles={[
              Roles.InstallerAdmin,
              Roles.InspectorAdmin,
              Roles.FacilityManagerAdmin,
              Roles.FacilityManager,
              Roles.ServiceEngineerAdmin,
            ]}
          />
          <ProtectedRoute path={Routes.Dashboard} component={Dashboard} roles={[Roles.FacilityManagerAdmin]} />
          <ProtectedRoute
            path={Routes.Reports}
            component={Reports}
            roles={[Roles.FacilityManagerAdmin, Roles.InspectorAdmin, Roles.InstallerAdmin]}
          />
          <Route path="*">
            <Redirect to={Routes.Login} />
          </Route>
        </Switch>
        <CookiePopup />
      </BrowserRouter>
    </AppContextProvider>
  );
}

export default App;
