import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import DeleteForever from '@material-ui/icons/DeleteForever';
import BuildIcon from '@material-ui/icons/Build';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import NoMeetingRoomIcon from '@material-ui/icons/NoMeetingRoom';
import ViewArrayIcon from '@material-ui/icons/ViewArray';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import Edit from '@material-ui/icons/Edit';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import HistoryEventTypes from '../../../../constants/HistoryEventTypes';
import { DoorHistoryFragment } from '../../../../queries';
import DoorInspectionDetailDialog from './details/DoorInspectionDetailDialog';
import DoorInstallationDetailDialog from './details/DoorInstallationDetailDialog';
import DoorNotAccessibleDetailDialog from './details/DoorNotAccessibleDetailDialog';
import DoorRetiredDetailDialog from './details/DoorRetiredDetailDialog';
import DoorRepairingDetailDialog from './details/DoorRepairingDetailDialog';
import DoorAssignedDetailDialog from './details/DoorAssignedDetailDialog';
import DoorInstallationReportedDetailDialog from './details/DoorInstallationReportedDetailDialog';
import DoorRepairRequestedDetailDialog from './details/DoorRepairRequestedDetailDialog';
import DoorUpdatedDetailDialog from './details/DoorUpdatedDetailDialog';
import DoorUnassignedDetailDialog from './details/DoorUnassignedDetailDialog';
import PersonOff from '../../../icons/PersonOff';
import PersonAddAlt1 from '../../../icons/PersonAddAlt1';
import DoorPPMDetailDialog from './details/DoorPPMDetailDialog';

const useStyles = makeStyles((theme) => ({
  historyItem: {
    backgroundColor: '#42445D',
    padding: theme.spacing(2),
  },
  historyTitle: {
    fontWeight: 600,
    color: '#fff',
  },
  historySubtitle: {
    fontWeight: 400,
  },
}));

interface DoorHistoryItemProps {
  history: DoorHistoryFragment;
  showDate?: boolean;
  hideConnector?: boolean;
}

function HistoryTypeIcon(history: DoorHistoryFragment) {
  switch (history.historyEventType.code) {
    case HistoryEventTypes.DoorAdded:
      return <AddCircleOutlineIcon />;
    case HistoryEventTypes.DoorInspectionPassed:
    case HistoryEventTypes.DoorInspectionFailed:
    case HistoryEventTypes.DoorPPMPassed:
    case HistoryEventTypes.DoorPPMFailed:
      return <DateRangeOutlinedIcon />;
    case HistoryEventTypes.DoorInstallationPassed:
    case HistoryEventTypes.DoorInstallationFailed:
    case HistoryEventTypes.DoorInstallationReported:
      return <ViewArrayIcon />;
    case HistoryEventTypes.DoorRetired:
      return <DeleteForever />;
    case HistoryEventTypes.DoorNotAccessible:
      return <NoMeetingRoomIcon />;
    case HistoryEventTypes.DoorRepairRequested:
    case HistoryEventTypes.DoorRepaired:
      return <BuildIcon />;
    case HistoryEventTypes.DoorAssigned:
      return <PersonAddAlt1 />;
    case HistoryEventTypes.DoorUnassigned:
      return <PersonOff />;
    case HistoryEventTypes.DoorUpdated:
      return <Edit />;
    default:
      return <ViewArrayIcon />;
  }
}

function DetailDialog(history: DoorHistoryFragment, open: boolean, onClose: () => void) {
  switch (history.historyEventType.code) {
    case HistoryEventTypes.DoorInstallationPassed:
    case HistoryEventTypes.DoorInstallationFailed:
      return <DoorInstallationDetailDialog history={history} open={open} onClose={onClose} />;
    case HistoryEventTypes.DoorInstallationReported:
      return <DoorInstallationReportedDetailDialog history={history} open={open} onClose={onClose} />;
    case HistoryEventTypes.DoorInspectionPassed:
    case HistoryEventTypes.DoorInspectionFailed:
      return <DoorInspectionDetailDialog history={history} open={open} onClose={onClose} />;
    case HistoryEventTypes.DoorPPMPassed:
    case HistoryEventTypes.DoorPPMFailed:
      return <DoorPPMDetailDialog history={history} open={open} onClose={onClose} />;
    case HistoryEventTypes.DoorRetired:
      return <DoorRetiredDetailDialog history={history} open={open} onClose={onClose} />;
    case HistoryEventTypes.DoorNotAccessible:
      return <DoorNotAccessibleDetailDialog history={history} open={open} onClose={onClose} />;
    case HistoryEventTypes.DoorRepaired:
      return <DoorRepairingDetailDialog history={history} open={open} onClose={onClose} />;
    case HistoryEventTypes.DoorRepairRequested:
      return <DoorRepairRequestedDetailDialog history={history} open={open} onClose={onClose} />;
    case HistoryEventTypes.DoorAssigned:
      return <DoorAssignedDetailDialog history={history} open={open} onClose={onClose} />;
    case HistoryEventTypes.DoorUnassigned:
      return <DoorUnassignedDetailDialog history={history} open={open} onClose={onClose} />;
    case HistoryEventTypes.DoorUpdated:
      return <DoorUpdatedDetailDialog history={history} open={open} onClose={onClose} />;
    default:
      return null;
  }
}

export default function DoorHistoryItem({ history, hideConnector, showDate }: DoorHistoryItemProps) {
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const classes = useStyles();
  const createdAt = dayjs(history.createdAt);

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color="secondary">{HistoryTypeIcon(history)}</TimelineDot>
        {!hideConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        {showDate && <Typography variant="h6">{createdAt.format('D MMMM YYYY')}</Typography>}
        <Typography variant="subtitle2" className={classes.historySubtitle}>
          {createdAt.format('HH:mm')}
        </Typography>
        <Paper className={classes.historyItem}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <Typography variant="body2" className={classes.historyTitle}>
                {history.historyEventType.name}
              </Typography>
              <Typography variant="subtitle2" component="p" className={classes.historySubtitle}>
                {`${history.addedByName} - ${history.addedByCompanyName}`}
              </Typography>
            </Box>
            {history.additionalInfoJson && (
              <IconButton aria-label="view details" onClick={() => setDetailDialogOpen(true)}>
                <VisibilityIcon />
              </IconButton>
            )}
          </Box>
        </Paper>
      </TimelineContent>
      {DetailDialog(history, detailDialogOpen, () => {
        setDetailDialogOpen(false);
      })}
    </TimelineItem>
  );
}
