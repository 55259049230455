import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import DoorContext from '../../../context/DoorContext';
import { DoorFormData, DoorDocument } from '../../../pages/DoorAdd';
import DoorDocumentsUpload from './DoorDocumentsUpload';
import { doorDocumentTypes } from '../../../constants/DocumentTypes';

interface DoorDocumentationAddEditProps {
  onContinue: () => void;
  onBack: () => void;
}

export default function DoorDocumentationAdd({ onContinue, onBack }: DoorDocumentationAddEditProps) {
  const { setDoor, door, doorModel } = useContext(DoorContext);
  const [documents, setDocuments] = useState<DoorDocument[]>(door?.documents ?? []);

  const form = useForm<DoorFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: door,
  });

  const { handleSubmit } = form;

  const submit = (values: any) => {
    setDoor((prev: DoorFormData | undefined) => ({ ...prev, documents }));
    onContinue();
  };

  const onBackClick = () => {
    setDoor((prev: DoorFormData | undefined) => ({ ...prev, documents }));
    onBack();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(submit)}>
        <DoorDocumentsUpload
          documents={documents}
          setDocuments={setDocuments}
          modelDocuments={doorModel?.doorModel.documents}
          documentTypeCodes={doorDocumentTypes}
        />
        <Box display="flex" justifyContent="space-between" marginTop={3}>
          <Button variant="outlined" onClick={onBackClick}>
            Back
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Continue
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
}
