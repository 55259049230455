import create from 'zustand';
import { Door } from '../queries';
type StoreState = {
  doors: Partial<Door>[];
  setDoors: (doors: Partial<Door>[]) => void;
};

const useRemoveDoorsContractorStore = create<StoreState>((set) => ({
  doors: [],
  setDoors: (doors: Partial<Door>[]) => set(() => ({ doors })),
}));

export default useRemoveDoorsContractorStore;
