import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { InspectionReport } from '../../../../../constants/DocumentTypes';
import HistoryEventTypes from '../../../../../constants/HistoryEventTypes';
import { DoorHistoryFragment } from '../../../../../queries';
import DocumentRow from '../../../../DocumentRow';
import DoorHistoryDetailDialog from '../DoorHistoryDetailDialog';
import StandardGridItem from './StandardGridItem';

interface DoorInspectionAdditionalInfo {
  message?: string;
  inspectedBy: string;
  ppmId: number;
  site: string;
  location: string;
  locationType?: string;
  reportId?: number;
  reportOriginalFilename: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  history: DoorHistoryFragment;
}

interface DetailDialogProps {
  history: DoorHistoryFragment;
}

function DetailDialog({ history }: DetailDialogProps) {
  if (!history.additionalInfoJson) {
    return <></>;
  }

  const additionalInfo = JSON.parse(history.additionalInfoJson) as DoorInspectionAdditionalInfo;
  return (
    <Box pt={1} pb={1}>
      <Grid container spacing={3}>
        <StandardGridItem title="Site" value={additionalInfo.site} />
        <StandardGridItem title="Location" value={additionalInfo.location} />
        {additionalInfo.locationType && (
          <StandardGridItem title="Location type" value={additionalInfo.locationType} minWidth={12} />
        )}

        {additionalInfo.message && <StandardGridItem title={'Comment'} value={additionalInfo.message} minWidth={12} />}

        {additionalInfo.reportId && (
          <DocumentRow
            originalFilename={additionalInfo.reportOriginalFilename}
            documentType={{ code: InspectionReport, name: 'Inspection report' }}
            documentId={additionalInfo.reportId}
          />
        )}
      </Grid>
    </Box>
  );
}

export default function DoorPPMDetailDialog({ open, onClose, history }: Props) {
  const isPass = history.historyEventType.code === HistoryEventTypes.DoorPPMPassed;
  const actorName = isPass ? 'Signed off' : 'Failed by';
  return (
    <DoorHistoryDetailDialog open={open} onClose={onClose} history={history} actorName={actorName}>
      <DetailDialog history={history} />
    </DoorHistoryDetailDialog>
  );
}
