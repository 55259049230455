export const Photo = 'Photo';
export const Drawing = 'Drawing';
export const InstallationGuide = 'InstallationGuide';
export const FireCertificate = 'FireCertificate';
export const Warranty = 'Warranty';
export const TechnicalDrawing = 'TechnicalDrawing';
export const SBDCertificate = 'SBDCertificate';
export const AcousticCertificate = 'AcousticCertificate';
export const InstallationReport = 'InstallationReport';
export const InstallationCertificate = 'InstallationCertificate';
export const InspectionReport = 'InspectionReport';
export const InspectionCertificate = 'InspectionCertificate';
export const CSV = 'csv';
export const NoAccessPhoto = 'NoAccessPhoto';
export const ThirdPartyDocumentation = 'ThirdPartyDocumentation';

// These are the document types that can be added/edited manually
export const doorDocumentTypes = [
  InstallationGuide,
  FireCertificate,
  Warranty,
  TechnicalDrawing,
  SBDCertificate,
  AcousticCertificate,
  Drawing,
  ThirdPartyDocumentation,
];
