import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MaterialLink from '@material-ui/core/Link';
import React, { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Layout from '../components/Layout';
import { Company, useRemoveContractorFromDoorsMutation } from '../queries';
import * as Routes from '../constants/Routes';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import useDialog from '../hooks/useDialog';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { GraphQLError } from 'graphql';
import { Link } from 'react-router-dom';
import useRemoveDoorsContractorStore from '../store/RemoveDoorsContractorStore';
import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

interface FormData {
  contractor: Pick<Company, 'id' | 'name'>;
}

export default function RemoveDoorsContractor() {
  const { showDialog } = useDialog();
  const history = useHistory();
  const location = useLocation();
  const { id: siteId } = useParams<any>();
  const { enqueueSnackbar } = useSnackbar();
  const { doors, setDoors } = useRemoveDoorsContractorStore();

  const [removeDoorsToContractor] = useRemoveContractorFromDoorsMutation();

  const form = useForm<FormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { handleSubmit, formState, errors, control } = form;

  const onCancelClicked = () => {
    showDialog({
      dialogText: 'Are you sure you want to cancel this operation?',
      okText: 'Yes',
      cancelText: 'No',
      onClose: () => {
        setDoors([]);
        history.goBack();
      },
    });
  };

  const saveChanges = async (values: FormData) => {
    try {
      await removeDoorsToContractor({
        variables: {
          contractorCompanyId: values.contractor.id,
          ids: doors.map((door) => Number(door.id)),
        },
      });

      enqueueSnackbar('Contractor removed successfully.', { variant: 'success' });
      history.goBack();
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        e.graphQLErrors.forEach((gqlerror: GraphQLError) => {
          enqueueSnackbar(gqlerror.message, { variant: 'error' });
        });
      }
    }
  };

  const activeContractors = useMemo(() => {
    if (!doors || doors.length === 0) {
      return [];
    }

    const contractors = new Map<string, Company>();
    const assignments = doors.flatMap((d) => d.activeDoorAssignments);

    for (const assignment of assignments) {
      if (!assignment) {
        continue;
      }
      if (!contractors.has(assignment.contractorCompany.id)) {
        contractors.set(assignment.contractorCompany.id, assignment.contractorCompany);
      }
    }
    return Array.from(contractors.values());
  }, [doors]);

  return (
    <Layout>
      <Grid container>
        <Grid item xs={1} xl={2} />
        <Grid item xs={10} xl={8}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5">Remove contractor</Typography>
              <Breadcrumbs aria-label="breadcrumb">
                <MaterialLink color="inherit" component={Link} to={Routes.MySites}>
                  <Typography variant="body2">My site list</Typography>
                </MaterialLink>
                {(location?.state as any)?.siteName && (
                  <MaterialLink color="inherit" component={Link} to={`${Routes.Sites}/${siteId}`}>
                    <Typography variant="body2">{(location?.state as any)?.siteName}</Typography>
                  </MaterialLink>
                )}
                <Typography variant="body2">Remove contractor</Typography>
              </Breadcrumbs>
            </Box>
            <Box minWidth={96}>
              <Button variant="outlined" onClick={onCancelClicked}>
                Cancel
              </Button>
            </Box>
          </Box>
          <Box mt={1}>
            <Paper>
              <Box p={5} marginTop={3}>
                <Box marginBottom={3}>
                  <Typography>Select a contractor from the dropdown to remove them from the selected doors.</Typography>
                </Box>
                <FormProvider {...form}>
                  <form onSubmit={handleSubmit(saveChanges)}>
                    <FormControl variant="outlined" fullWidth>
                      <Controller
                        control={control}
                        name="contractor"
                        defaultValue={null}
                        rules={{
                          required: 'Company is required',
                        }}
                        render={({ onChange, value }) => (
                          <Autocomplete
                            fullWidth
                            autoSelect
                            autoComplete
                            selectOnFocus
                            handleHomeEndKeys
                            freeSolo
                            getOptionLabel={(option) => {
                              if (typeof option === 'string') {
                                return option;
                              }
                              return option.name;
                            }}
                            options={activeContractors}
                            value={value?.name ?? null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Company"
                                variant="outlined"
                                fullWidth
                                error={!!errors.contractor}
                              />
                            )}
                            onChange={(_, newValue: Pick<Company, 'id' | 'name'> | string | null) => {
                              if (!newValue) {
                                onChange(newValue);
                              }

                              if (typeof newValue === 'string') {
                                const existingOption = activeContractors.find((f) => f.name.trim() === newValue.trim());
                                if (existingOption) {
                                  onChange({ id: existingOption.id, name: existingOption.name });
                                } else {
                                  onChange(undefined);
                                }
                              }
                            }}
                            getOptionSelected={(option, value) => option.id === value.id}
                          />
                        )}
                      />
                    </FormControl>
                    <Box display="flex" justifyContent="flex-end" marginTop={3}>
                      <Button type="submit" variant="contained" color="primary" disabled={!formState.isValid}>
                        Remove contractor
                      </Button>
                    </Box>
                  </form>
                </FormProvider>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={1} xl={2} />
      </Grid>
    </Layout>
  );
}
