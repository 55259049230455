import React from 'react';

type Props = {
  size?: number;
};
const PersonAddAlt1 = ({ size = 24 }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.833 11.6673C15.5969 11.6673 15.3991 11.5873 15.2397 11.4273C15.0797 11.2679 14.9997 11.0701 14.9997 10.834V9.16732H13.333C13.0969 9.16732 12.8991 9.08732 12.7397 8.92732C12.5797 8.76787 12.4997 8.5701 12.4997 8.33398C12.4997 8.09787 12.5797 7.89982 12.7397 7.73982C12.8991 7.58037 13.0969 7.50065 13.333 7.50065H14.9997V5.83398C14.9997 5.59787 15.0797 5.39982 15.2397 5.23982C15.3991 5.08037 15.5969 5.00065 15.833 5.00065C16.0691 5.00065 16.2669 5.08037 16.4263 5.23982C16.5863 5.39982 16.6663 5.59787 16.6663 5.83398V7.50065H18.333C18.5691 7.50065 18.7669 7.58037 18.9263 7.73982C19.0863 7.89982 19.1663 8.09787 19.1663 8.33398C19.1663 8.5701 19.0863 8.76787 18.9263 8.92732C18.7669 9.08732 18.5691 9.16732 18.333 9.16732H16.6663V10.834C16.6663 11.0701 16.5863 11.2679 16.4263 11.4273C16.2669 11.5873 16.0691 11.6673 15.833 11.6673ZM7.49967 10.0007C6.58301 10.0007 5.79829 9.67426 5.14551 9.02148C4.49273 8.36871 4.16634 7.58398 4.16634 6.66732C4.16634 5.75065 4.49273 4.96593 5.14551 4.31315C5.79829 3.66037 6.58301 3.33398 7.49967 3.33398C8.41634 3.33398 9.20106 3.66037 9.85384 4.31315C10.5066 4.96593 10.833 5.75065 10.833 6.66732C10.833 7.58398 10.5066 8.36871 9.85384 9.02148C9.20106 9.67426 8.41634 10.0007 7.49967 10.0007ZM1.66634 16.6673C1.43023 16.6673 1.23245 16.5873 1.07301 16.4273C0.913008 16.2679 0.833008 16.0701 0.833008 15.834V14.334C0.833008 13.8618 0.954674 13.4276 1.19801 13.0315C1.44079 12.6359 1.76356 12.334 2.16634 12.1257C3.02745 11.6951 3.90245 11.372 4.79134 11.1565C5.68023 10.9415 6.58301 10.834 7.49967 10.834C8.41634 10.834 9.31912 10.9415 10.208 11.1565C11.0969 11.372 11.9719 11.6951 12.833 12.1257C13.2358 12.334 13.5586 12.6359 13.8013 13.0315C14.0447 13.4276 14.1663 13.8618 14.1663 14.334V15.834C14.1663 16.0701 14.0863 16.2679 13.9263 16.4273C13.7669 16.5873 13.5691 16.6673 13.333 16.6673H1.66634Z"
        fill="white"
      />
    </svg>
  );
};

export default PersonAddAlt1;
